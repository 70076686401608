<template>
  <div
    v-if="loadedPage"
    class="admin-entity-content--container">

    <EntityEditor
      v-if="loadedPage"
      :title="offer.first_header"
      entity-type="Offer"
      :entity="offer"
      :admin="true"
      :breadcrumb="offer.goodiebag._id"/>

    <div class="admin-entity--subentity">
      <div class="subentity">
        <div class="admin-entity--title">PV link</div>
        <InputField
          label="Change the pvId"
          v-model="pvLink"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EventBus from "../resources/eventBus";
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';
import EntityEditor from '../stories/components/EntityEditor/EntityEditor.vue';
import InputField from '../stories/components/InputField/InputField.vue';

export default {
  name: 'AdminOffer',
  components: {
    EntityEditor,
    InputField,
  },
  props: ['offerId'],
  mixins: [BeforeEnterMixin],
  mounted() {
    this.loadPage();
  },
  destroyed() {
    EventBus.$emit('selected', '');
  },
  computed: {
    ...mapGetters(["offer", "userStatus"]),
  },
  watch: {
    offerId(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadPage();
      }
    }
  },
  data() {
    return {
      loadedPage: false,
      pvLink: '',
    };
  },
  methods: {
    loadPage() {
      this.loadedPage = false;
      this.$store.dispatch('getAdminOffer', { entityId: this.offerId }).then(() => {
        this.pvLink = `${this.windowOrigin}/pv/pvId/${this.offerId}`,
        this.loadedPage = true;
        this.setPageTitle('Admin', 'Activation');
      });
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/scss/pages/AdminOffer";
</style>
