/* eslint-disable no-shadow, no-param-reassign */
import Vue from 'vue';
import userApi from '../../api/user.api';
import EventBus from '../../resources/eventBus';
import enginioText from '../../assets/enginio_text';

// initial state
const state = {
  user: {},
  activation: {},
  receipts: [],
  // currentGoodiebag: {},
  userStatus: '',
  userGdpr: {},
  surveyUpload: {},

  authenticated: false,
  username: localStorage.getItem('username'),

  fromRoute: null,

  transl: enginioText.EN,
  language: 'EN',

  loadedPageData: false,

  myProductsStatus: '',
  myProducts: []
};

// getters
const getters = {
  user: state => state.user,
  activation: state => state.activation,
  receipts: state => state.receipts,
  // currentGoodiebag: state => state.currentGoodiebag,
  userStatus: state => state.userStatus,
  userGdpr: state => state.userGdpr,
  surveyUpload: state => state.surveyUpload,

  authenticated: state => state.authenticated,
  username: state => state.username,

  fromRoute: state => state.fromRoute,

  transl: state => state.transl,
  language: state => state.language,

  loadedPageData: state => state.loadedPageData,
  myProducts:state => state.myProducts,
  myProductsStatus :state => state.myProductsStatus,
};

// actions
const actions = {
  // Consumer
  signupLoginUser({ commit }, payload) {
    commit('USER_STATUS', 'LOADING');
    return userApi.signupLoginUser(payload)
      .then((response) => {
        const { success, user } = response;
        // commit('SET_TRANSL', user.language);
        if (success) {
          commit('USER', user);
          commit('SET_USERNAME', user.name);
          commit('USER_STATUS', 'FINISHED');
          commit('SET_AUTHENTICATED', true);
        } else {
          commit('SET_AUTHENTICATED', false);
          commit('USER', {});
          commit('USER_STATUS', 'FINISHED');
          commit('SET_USERNAME', '');
        }
        return response;
      });
  },

  loginUser({ commit }, payload) {
    commit('USER_STATUS', 'LOADING');
    return userApi.loginUser(payload)
      .then((response) => {
        const { success, user } = response;
        // commit('SET_TRANSL', user.language);
        if (success) {
          commit('USER', user);
          commit('SET_USERNAME', user.name);
          commit('USER_STATUS', 'FINISHED');
          commit('SET_AUTHENTICATED', true);
        } else {
          commit('SET_AUTHENTICATED', false);
          commit('USER', {});
          commit('USER_STATUS', 'FINISHED');
          commit('SET_USERNAME', '');
        }
        return response;
      });
  },

  getUser({ commit }, payload) {
    commit('USER_STATUS', 'LOADING');
    return userApi.getUser(payload)
      .then((response) => {
        const { success, user, language } = response;
        commit('SET_TRANSL', language);
        if (success) {
          commit('USER', user);
          commit('SET_USERNAME', user.name);
          commit('USER_STATUS', 'FINISHED');
          commit('SET_AUTHENTICATED', true);
        } else {
          commit('SET_AUTHENTICATED', false);
          commit('USER', {});
          commit('USER_STATUS', 'FINISHED');
          commit('SET_USERNAME', '');
        }
        return response;
      });
  },

  getNarPV({ commit }, payload) {
    commit('USER_STATUS', 'LOADING');
    return userApi.getNarPV(payload.pvId)
      .then((response) => {
        commit('ACTIVATION', response.activation);
        commit('USER_STATUS', 'FINISHED');
        return true;
      })
      .catch(() => {
        return false
      });
  },

  getMyProducts({ commit }, payload) {
    commit('MYPRODUCTS_STATUS', 'LOADING');
    return userApi.getMyProducts(payload.companyId)
    .then((response) => {
      commit('MYPRODUCTS', response.receipts);
      commit('MYPRODUCTS_STATUS', 'FINISHED');
      return { products: response.receipts };
    });
  },

  getUserPV({ commit }, payload) {
    commit('USER_STATUS', 'LOADING');
    return userApi.getUserPV(payload)
      .then((response) => {
        commit('ACTIVATION', response.activation);
        commit('RECEIPTS', response.receipts);
        commit('USER_STATUS', 'FINISHED');
        const { canAddReceipt } = response;
        return { canAddReceipt, success: true };
      })
      .catch(() => {
        return { success: false };
      });
  },

  saveUserReceiptMetadata({ commit }, payload) {
    commit('USER_STATUS', 'LOADING');
    return userApi.saveUserReceiptMetadata(payload)
      .then((response) => {
        commit('ACTIVATION', response.activation);
        commit('USER_STATUS', 'FINISHED');
        return true;
      });
  },

  saveUserReceiptPicture({ commit }, payload) {
    commit('USER_STATUS', 'LOADING');
    return userApi.saveUserReceiptPicture(payload)
      .then((response) => {
        commit('ACTIVATION', response.activation);
        commit('USER_STATUS', 'FINISHED');
        return true;
      });
  },

  addUserGoodiebag({ commit }, payload) {
    commit('USER_STATUS', 'LOADING');
    return userApi.addUserGoodiebag(payload)
      .then((response) => {
        commit('USER', response.user);
        commit('USER_STATUS', 'FINISHED');
        return true;
      })
      .catch(() => {
        commit('USER_STATUS', 'FINISHED');
        commit('SET_AUTHENTICATED', false);
        return false;
      });
  },

  setFavoriteOffer({ commit }, payload) {
    return userApi.setFavoriteOffer(payload)
      .then((response) => {
        commit('USER', response.user);
        commit('USER_STATUS', 'FINISHED');
        return true;
      })
      .catch(() => {
        commit('USER_STATUS', 'FINISHED');
        return false;
      });
  },

  setLocalFavoriteOffer({ commit }, payload) {
    return userApi.setFavoriteOffer(payload)
      .then(() => {
        commit('SET_FAVORITE', payload);
        commit('USER_STATUS', 'FINISHED');
        return true;
      })
      .catch(() => {
        commit('USER_STATUS', 'FINISHED');
        return false;
      });
  },

  addUserSurveyOfferMedia({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit('SET_SURVEY_UPLOAD', payload);
      return resolve();
    });
  },

  activateOffer({ commit }, payload) {
    return userApi.activateOffer(payload)
      .then((response) => {
        commit('USER', response.user);
        commit('USER_STATUS', 'FINISHED');
        return {
          interleaved_code: response.interleaved_code,
          ean_link: response.ean_link,
          winner_adress: response.winner_adress,
          cta_email: response.cta_email,
          no_cta: response.no_cta,
          success: response.success,
        };
      });
  },

  userSurveyUpload({ commit }) {
    return userApi.userSurveyUpload(state.surveyUpload)
      .then((response) => {
        // commit('USER', response.user);
        commit('USER_STATUS', 'FINISHED');
        return;
      });
  },

  activateHomeOffer({ commit }, payload) {
    return userApi.activateHomeOffer(payload)
      .then((response) => {
        commit('USER_STATUS', 'FINISHED');
        return { message: response.message };
      });
  },

  donateOffer({ commit }, payload) {
    return userApi.donateOffer(payload)
      .then((response) => {
        commit('USER', response.user);
        commit('USER_STATUS', 'FINISHED');
        return true;
      });
  },

  changeUser({ commit }, payload) {
    return userApi.changeUser(payload)
      .then((response) => {
        commit('USER', response.user);
        commit('USER_STATUS', 'FINISHED');
        return true;
      })
      .catch(() => {
        commit('USER_STATUS', 'FINISHED');
        return false;
      });
  },

  changeEmail({ commit }, payload) {
    return userApi.changeEmail(payload)
      .then((response) => {
        commit('USER', response.user);
        commit('USER_STATUS', 'FINISHED');
        return true;
      })
      .catch(() => {
        commit('USER_STATUS', 'FINISHED');
        return false;
      });
  },

  deleteUserDevice({ commit, rootState }, payload) {
    return userApi.deleteUserDevice(payload)
    .then((response) => {
      const user = response.user;
      let logOut = true;
      for (let index = 0; index < user.devices.length; index++) {
        const device = user.devices[index];
        if (rootState.device.deviceId === device.visitor_id) {
          logOut = false;
        }
      }
      if (logOut) {
        return EventBus.$emit('logout');
      }
      commit('USER', user);
      commit('USER_STATUS', 'FINISHED');
      return { message: response.message };
    });
  },

  subscribeEmail({ commit }) {
    return userApi.subscribeEmail()
      .then((response) => {
        commit('USER', response.user);
        commit('USER_STATUS', 'FINISHED');
        return response.message;
      })
      .catch(() => {
        commit('USER_STATUS', 'FINISHED');
        return false;
      });
  },

  subscribeParentCompany({ commit }, payload) {
    return userApi.subscribeParentCompany(payload)
      .then((response) => {
        commit('USER', response.user);
        commit('USER_STATUS', 'FINISHED');
        return response.message;
      })
      .catch(() => {
        commit('USER_STATUS', 'FINISHED');
        return false;
      });
  },

  acceptOfferTerms({ commit }, payload) {
    return userApi.acceptOfferTerms(payload)
      .then((response) => {
        commit('USER', response.user);
        commit('USER_STATUS', 'FINISHED');
        return response.message;
      })
      .catch(() => {
        commit('USER_STATUS', 'FINISHED');
        return false;
      });
  },

  deleteUser({ commit }) {
    commit('USER_STATUS', 'LOADING');
    return userApi.deleteUser()
      .then(() => {
        commit('SET_AUTHENTICATED', false);
        commit('USER', {});
        commit('USER_STATUS', 'FINISHED');
        commit('SET_USERNAME', '');
        return;
      });
  },

  setAuthenticated({ commit }, authenticated) {
    commit('SET_AUTHENTICATED', authenticated);
  },

  setUsername({ commit }, username) {
    commit('SET_USERNAME', username);
  },

  setFromRoute({ commit }, fromRoute) {
    commit('SET_FROM_ROUTE', fromRoute);
  },

  setLoadedPageData({ commit }, loadedPageData) {
    commit('SET_LOADED_PAGE_DATA', loadedPageData);
  },

  logoutFeUser({commit}) {
    commit('SET_AUTHENTICATED', false);
    commit('USER', {});
    commit('USER_STATUS', 'FINISHED');
    commit('SET_USERNAME', '');
    return true;
  },

  logoutUser({ commit }) {
    commit('USER_STATUS', 'LOADING');
    return userApi.logoutUser()
      .then(() => {
        commit('SET_AUTHENTICATED', false);
        commit('USER', {});
        commit('USER_STATUS', 'FINISHED');
        commit('SET_USERNAME', '');
        return true;
      })
      .catch(() => {
        return false;
      });
  },

  resetActivation({ commit }) {
    commit('ACTIVATION', {});
  },

  // Admin
  bookmarkAdminGoodiebag({ commit }, payload) {
    commit('USER_STATUS', 'LOADING');
    return userApi.bookmarkAdminGoodiebag(payload)
      .then((response) => {
        commit('USER', response.user);
        commit('USER_STATUS', 'FINISHED');
      });
  },

  bookmarkAdminOffer({ commit }, payload) {
    commit('USER_STATUS', 'LOADING');
    return userApi.bookmarkAdminOffer(payload)
      .then((response) => {
        commit('USER', response.user);
        commit('USER_STATUS', 'FINISHED');
      });
  },

  // Company
  bookmarkCompanyGoodiebag({ commit }, payload) {
    commit('USER_STATUS', 'LOADING');
    return userApi.bookmarkCompanyGoodiebag(payload)
      .then((response) => {
        commit('USER', response.user);
        commit('USER_STATUS', 'FINISHED');
      });
  },

  bookmarkCompanyOffer({ commit }, payload) {
    commit('USER_STATUS', 'LOADING');
    return userApi.bookmarkCompanyOffer(payload)
      .then((response) => {
        commit('USER', response.user);
        commit('USER_STATUS', 'FINISHED');
      });
  },
};

// mutations
const mutations = {
  USER(state, user) {
    let localUser = user;
    localUser.organizedDevices = groupBy(user.devices, 'device_type');
    state.user = localUser;
  },
  ACTIVATION(state, activation) {
    state.activation = activation;
  },
  RECEIPTS(state, receipts) {
    state.receipts = receipts;
  },
  /*
  SET_CURRENT_GOODIEBAG(state, goodiebagId) {
    for (let i = 0; i < state.user.favorite_goodiebags.length; i += 1) {
      if (state.user.favorite_goodiebags[i]._id.toString() === goodiebagId) {
        state.currentGoodiebag = state.user.favorite_goodiebags[i];
      }
    }
  },
  */
  SET_FAVORITE(state, data) {
    for (let i = 0; i < state.user.favorite_offers.length; i += 1) {
      if (state.user.favorite_offers[i]._id === data.offerId) {
        Vue.set(state.user.favorite_offers[i], 'favorite', data.favorite);
      }
    }
  },
  USER_STATUS(state, status) {
    state.userStatus = status;
  },
  SET_AUTHENTICATED(state, authenticated) {
    state.authenticated = authenticated;
  },
  SET_USERNAME(state, username) {
    let shortUsername = username;
    if (username) {
      shortUsername = username.split(' ')[0].substring(0, 8);
      localStorage.setItem('username', shortUsername);
    }
    state.username = shortUsername;
  },
  SET_SURVEY_UPLOAD(state, surveyUpload) {
    state.surveyUpload =  surveyUpload;
  },

  SET_FROM_ROUTE(state, fromRoute) {
    state.fromRoute = fromRoute;
  },

  SET_TRANSL(state, language) {
    if (!enginioText[language]) {
      console.log(`[SET_TRANSL] No translations for given language "${language}".`);
      language = 'EN';
    }
    console.log(`[SET_TRANSL] Setting language to: ${language}`);
    state.language = language;
    state.transl = enginioText[language];
    EventBus.$emit('CHANGED_TRANSL');
  },

  SET_LOADED_PAGE_DATA(state, loadedPageData) {
    state.loadedPageData = loadedPageData;
  },
  MYPRODUCTS_STATUS(state, status){
    state.myProductsStatus = status;
  },
  MYPRODUCTS(state, receipts) {
    state.myProducts = receipts;
  },
};

function groupBy(objectArray, property) {
  if (objectArray) {
    return objectArray.reduce(function (acc, obj) {
      let key = obj[property];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
  } else {
    return {};
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
};
