<template>
  <div
       class="admin-content--container">
    <div class="admin-search-container">
      <div class="admin-spacer"></div>

      <div class="admin-search-container">
         <InputField
            v-model="query"
            placeholder="Find QrHunt Game..."
            trailingIcon="magnifyingGlass"
            clickableTrailingIcon
            @trailingIconClick="findAdminGames()"
            @enterKey="findAdminGames()"/>

         <div class="add-entity-button" @click="showQrHuntDialog = true">
           <iconComponent symbol="circledPlus" />
         </div>
      </div>
    </div>

    <aList
      :entityType="`${gameType}Game`"
      columnsWidth="400"
      :entities="games"
      :keys="qrHuntKeys"
      @click="$router.push({ name: 'AdminGame', params: { gameType: gameType, gameId: games[$event.index]._id } })" />

    <modal v-if="showQrHuntDialog" size="xlarge"
           @close="showQrHuntDialog = false;" modalType="fullscreen">
      <slot>
        <EntityEditor
          entity-type="QrHuntGame"
          :admin="true"/>
      </slot>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';
import aList from "../components/AList";
import InputField from '../stories/components/InputField/InputField.vue';
import EntityEditor from '../stories/components/EntityEditor/EntityEditor.vue'

export default {
  name: 'AdminGames',
  components: {
    aList,
    InputField,
    EntityEditor
  },
  props: [
    'gameType',
  ],
  mixins: [BeforeEnterMixin],
  mounted() {
    this.findAdminGames();
    this.setPageTitle('Admin', 'Games');
  },
  destroyed() {
    // EventBus.$emit('selected', '');
  },
  computed: {
    ...mapGetters('games', ['games']),
  },
  data() {
    return {
      showQrHuntDialog: false,
      loadedPage: false,
      query: '',
      qrHuntKeys: [
        {
          title: 'Id',
          key: '_id',
        },
        {
          title: 'Name',
          key: 'name',
        },
        {
          title: 'Public',
          key: 'public',
          style: [{ color: 'red', value: false }, { color: 'green', value: true }],
        },
        {
          title: 'Created At',
          key: 'created_at',
          unix: 'YYYY-MM-DD HH:mm:ss',
        },
      ],
    };
  },
  methods: {
    findAdminGames() {
      this.$store.dispatch('games/findAdminGames', {
        query: this.query,
        entityType: 'QrHunt',
      }).then(() => {
        this.loadedPage = true;
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '../assets/scss/pages/AdminGames';
</style>