import axios from 'axios';

export default {
  // Admin
  findAdminPVCampaign: (data) => {
    return axios.put(`/api/pv/admin/campaigns`, data)
      .then(response => {
        return {
          campaigns: response.data.campaigns != null
            ? response.data.campaigns : [],
      }});
  },

  addAdminPVCampaign: (payload) => {
    return axios.post('/api/pv/admin/campaigns', payload)
      .then(response => ({
        campaign: response.data.campaign != null
          ? response.data.campaign : {},
      }));
  },

  saveAdminPVCampaign: (payload) => {
    return axios.put(`/api/pv/admin/campaigns/${payload.entityId}`, payload)
      .then(response => ({
        campaign: response.data.campaign != null
          ? response.data.campaign : {},
      }));
  },

  getAdminPVCampaign: (payload) => {
    return axios.get(`/api/pv/admin/campaigns/${payload.entityId}`, payload)
      .then(response => ({
        campaign: response.data.campaign != null
          ? response.data.campaign : {},
      }));
  },

  deleteAdminPVCampaign: (payload) => {
    return axios.delete(`/api/pv/admin/campaigns/${payload.entityId}?deleteCode=${payload.deleteCode}`)
      .then(response => ({
        message: response.data.message != null
          ? response.data.message : '',
      }));
  },

  getAdminPVExangerates: (from) => {
    return axios.get(`/api/pv/admin/exchange-rates/${from}`)
      .then(response => ({
        rates: response.data.rates != null
          ? response.data.rates : {},
      }));
  },

  // Company
  findCompanyCampaigns: (data) => {
    return axios.put('/api/pv/c/campaigns', data)
      .then(response => {
        return { campaigns: response.data.campaigns != null
          ? response.data.campaigns : []}
      });
  },

  getCompanyCampaign: (payload) => {
    return axios.get(`/api/pv/c/campaigns/${payload.campaignId}`)
      .then(response => ({
        campaign: response.data.campaign != null
          ? response.data.campaign : {},
      }));
  },

  addCompanyCampaign: (payload) => {
    return axios.post('/api/pv/c/campaigns', payload)
      .then(response => ({
        campaign: response.data.campaign != null
          ? response.data.campaign : {},
      }));
  },

  saveCompanyCampaign: (payload) => {
    return axios.put(`/api/pv/c/campaigns/${payload.entityId}`, payload)
      .then(response => ({
        campaign: response.data.campaign != null
          ? response.data.campaign : {},
      }));
  },

  deleteCompanyCampaign: (entityId) => {
    return axios.delete(`/api/pv/c/campaigns/${entityId}`)
      .then(response => ({
        message: response.data.message != null
          ? response.data.message : '',
      }));
  },
};
