<template>
  <div>
    <div v-if="loadedPage && offer && iframeWebsiteUrl" class="iframe-offer">
      <!--:class="{ 'iframe-no-auth': !!offer.must_login }"-->
      <iframe :src="iframeWebsiteUrl" allow="camera;">
      </iframe>

      <!--<div v-if="!!offer.must_login" class="btn btn-activate btn-fixed" @click="setLoginView()">
        {{ offer.link_button_title || 'Logga in' }}
      </div>-->
    </div>

    <div
        v-else-if="offer && goodiebag && loadedPage && (expiresAtState !== 'expired' || expiredOffer || offer.closed)"
        class="user-offer-wrapper">
      <div class="user-offer">
        <div class="main-offer">
          <div v-if="offer.media && offer.media.length === 1 && offer.media[0].fileType.includes('image')"
              class="offer-header-image offer-single-image">
            <img :src="offer.media[0].url" alt="">

            <div v-if="!offer.unlocked" class="offer-jwt-lock">
              <iconComponent symbol="icons8Lock" width="30%" :stroke-width="0" fill="#FCEFE1" />
            </div>

            <div v-if="offer.closed" class="card-tag closed-card-chip">
              <md-chip class="card-chip">
                {{ transl.Offer.closed }}
              </md-chip>
            </div>

            <iconComponent
                          v-if="offer.terms_text"
                          symbol="info"
                          @click="tocModal = true"
                          style="cursor: pointer;" />

            <img v-if="offer.icon?.url || goodiebag.icon?.url"
                :src="`${!offer.unique_icon && goodiebag.icon?.url ? goodiebag.icon?.url : offer.icon?.url}`"
                class="offer-header-logo"
                @click="$router.replace({ name: 'HomeGoodiebag', params: { goodiebagId: getId(goodiebag) }})" />
          </div>

          <video v-else-if="offer.media && offer.media.length === 1 && offer.media[0].fileType.includes('video')"
                class="news-autoplay offer-header-image autoplayMedia" controls muted playsinline>
            <div v-if="offer.closed" class="card-tag closed-card-chip">
              <md-chip class="card-chip">
                {{ transl.Offer.closed }}
              </md-chip>
            </div>

            <iconComponent
                          v-if="offer.terms_text"
                          symbol="info"
                          @click="tocModal = true"
                          style="cursor: pointer;" />

            <source :src="`${offer.media && offer.media[0] ? offer.media[0].url : '' }`"
                    :type="offer.media[0].fileType">
            {{ transl.Offer.novideo }}
          </video>

          <div v-else-if="offer.media && offer.media.length > 1" class="offer-header-image carousel">
            <aCarousel
              :media-list="offer.media"
              :single-image="true"
              :single-arrows="true"/>

            <img v-if="offer.icon?.url || goodiebag.icon?.url"
                :src="`${!offer.unique_icon && goodiebag.icon?.url ? goodiebag.icon?.url : offer.icon?.url}`"
                class="offer-header-logo"
                @click="$router.replace({ name: 'HomeGoodiebag', params: { goodiebagId: getId(goodiebag) }})" />

            <div v-if="offer.closed" class="card-tag closed-card-chip">
              <md-chip class="card-chip">
                {{ transl.Offer.closed }}
              </md-chip>
            </div>

            <iconComponent
                          v-if="offer.terms_text"
                          symbol="info"
                          @click="tocModal = true"
                          style="cursor: pointer;" />
          </div>

          <iframe v-else-if="offer.iframe_media && offer.iframe_media.length"
                  class="offer-header-image offer-header-iframe"
                  :src="`https://player.vimeo.com/video/${offer.iframe_media[0].id}`" frameborder="0"
                  allow="autoplay; fullscreen" allowfullscreen>
          </iframe>

          <div class="offer-content-wrapper content-page">
            <div class="offer-fast-icons">
              <div v-if="offer.variations" class="offer-fast-text">
                <iconComponent symbol="style" />
                <span>{{ transl.Offer.variants }}</span>
              </div>

              <div v-else-if="offer.save_text" class="offer-fast-text">
                <iconComponent symbol="newReleases" />
                <span>{{ offer.save_text }}</span>
              </div>

              <div v-else class="offer-fast-text">
                <span style="padding: 0;">{{ offer.quick_text }}</span>
              </div>

              <div v-if="expiresDate" class="offer-fast-text-two">
                <iconComponent symbol="calendarToday" :width="20" />
                <span>{{ expiresDate }}</span>
              </div>

              <!--<div
                v-if="!offer.hide_text_box"
                class="offer-fast-text-two">
                <iconComponent symbol="calendarToday"/>
                <span>Kommer inom kort!</span>
              </div>-->

              <div class="offer-fast-icons-actions">
                <div class="btn-icon-text btn-description offer-fast-share" v-if="!offer.closed"
                    @click="setLoginView()">
                  <iconComponent symbol="heart" />
                  <!--<div class="md-description">{{ transl.Offer.save }}</div>-->
                </div>

                <div class="btn-icon-text btn-description offer-fast-share" v-if="offer.share && !offer.closed"
                    v-clipboard:copy="currentUrl" v-clipboard:success="onCopy" v-clipboard:error="onError">
                  <iconComponent symbol="share" />
                  <!--<div class="md-description">{{ transl.Offer.share }}</div>-->
                </div>

                <!--<input
                  id="share-link"
                  :value="currentUrl"
                  type="hidden">-->
              </div>
            </div>

            <div class="offer-header">{{ offer.first_header }}</div>
            <div class="offer-header-two" style="margin-bottom: 1.5rem;">
              <span v-html="offer.second_header"></span>
            </div>

            <div class="offer-survey" v-if="answeredSurvey">
              <iconComponent symbol="festival" :width="150" :stroke-width="0" fill="#EA7801" />

              <div style="padding-top: 1rem;">
                {{ transl.Offer.thanks }}
                <br />
                {{ transl.Offer.thanks2 }}
              </div>
            </div>

            <div class="offer-survey"
                v-else-if="!offer.must_login && offer.survey && offer.survey.length && !afterNow(offer.starts_at)">
              <div v-for="(question, index) in offer.survey" :key="question.label"
                  class="offer-survey-question">

                <label class="offer-survey-label subtitle-small"
                      :for="question.label">{{ index + 1 }}. {{ question.label }}</label>

                <div class="offer-survey-radio-wrapper"
                    v-if="question.options && question.options.length && question.radio">
                  <md-radio v-for="option in question.options" :key="option" :value="option" :name="question.model"
                            v-model="surveyAnswers[question.model]" class="md-primary">{{ option }}</md-radio>
                  <!--<md-radio v-if="question.other" key="other" :value="surveyOther[question.model]" :id="`${question.model}other`" :name="question.model"
                            @change="setRadio(question, 'other')" class="md-primary">
                    <InputField
                          :label="transl.Offer.other" :outlined="false" :filled="true"
                          @input="setRadio(question, 'other', true)"
                          v-model="surveyOther[question.model]"/>
                  </md-radio>-->
                </div>

                <div class="offer-survey-checkbox-wrapper"
                    v-else-if="question.options && question.options.length && question.checkbox">
                  <div
                    v-for="option in question.options"
                    :key="option"
                    class="offer-survey-checkbox-regular">
                    <input type="checkbox" class="offer-survey-checkbox" :value="option" :id="question.model"
                          @change="setCheckbox(question, option)" />
                    <span style="margin: 0 16px;">{{ option }}</span>
                  </div>
                  <div
                    v-if="question.other"
                    class="offer-survey-checkbox-other">
                    <input type="checkbox" class="offer-survey-checkbox" :id="`${question.model}other`"
                          @change="setCheckbox(question, 'other', surveyOther[question.model])"/>
                    <InputField
                        :label="transl.Offer.other" :outlined="false" :filled="true"
                        @input="setCheckbox(question, 'other', $event, true)"
                        style="margin-left: 16px; width: 93%;"
                        v-model="surveyOther[question.model]"/>
                  </div>
                </div>

                <div v-else-if="question.options && question.options.length && question.slider"
                    style="padding: 1rem 0 3.5rem 0;">
                  <img v-if="question.slider_options?.media && question.slider_options.media.length"
                      :src="question.slider_options.media[question.options.indexOf(surveyAnswers[question.model] || question.options[0])].url"
                      style="padding-bottom: 1rem" />
                  <VueSlider
                    v-model="surveyAnswers[question.model]"
                    v-bind="question.slider_options.options"
                    :data="question.options">
                  </VueSlider>
                </div>

                <md-field v-else-if="question.options && question.options.length">
                  <md-select v-model="surveyAnswers[question.model]" :name="question.label"
                            :id="question.label">
                    <md-option v-for="option in question.options" :key="option" :value="option">{{ option }}
                    </md-option>
                  </md-select>
                </md-field>

                <span v-else-if="question.checkbox" class="offer-survey-checkbox-wrapper">
                  <input type="checkbox" :id="question.label"
                        v-model="surveyAnswers[question.model]">{{ question.label }}
                </span>

                <md-datepicker v-else-if="question.date" v-model="surveyAnswers[question.model]">
                </md-datepicker>

                <div v-else :required="question.required">
                  <!--:class="{ 'incorrect-input' : surveyAnswers[question.model] !== '' && question.validator && !validator[question.validator](surveyAnswers[question.model]) }"-->
                  <InputField v-if="!question.checkbox || !question.radio || !question.slider || !question.date || !question.textarea"
                              label="" :outlined="true" :filled="false"
                              v-model="surveyAnswers[question.model]" />
                  <InputField v-if="question.textarea" inputType="textarea" placeholder="" label=""
                              v-model="surveyAnswers[question.model]" />
                  <span class="md-helper-text the-issue">{{ question.helper }}</span>
                </div>
              </div>
            </div>

            <!--<div
              class="offer-survey"
              v-if="offer.survey && offer.survey.length && !afterNow(offer.starts_at)">
              <div
                v-for="question in offer.survey"
                :key="question.label">
                <label
                  class="offer-survey-label"
                  style="margin-bottom: 1.5rem"
                  :for="question.label">{{ question.label }}</label>
              </div>
            </div>-->

            <div class="btn btn-activate btn-fixed"
                v-if="offer.external_link && !expiredOffer && offer.unlocked && !offer.must_login"
                @click="gotoExternalLink(offer.external_link)">
              {{ offer.link_button_title || transl.Offer.gotooffer }}
            </div>

            <div v-else-if="((offer.links_gallery && offer.links_gallery.links && offer.links_gallery.links.length) || offer.pdf)
                && !expiredOffer && offer.unlocked && !offer.must_login" class="btn btn-activate btn-fixed"
                @click="internalLinkModal = true">
              {{ offer.link_button_title }}
            </div>

            <div v-else-if="!answeredSurvey && !offer.must_login && offer.survey && offer.survey.length"
                class="btn btn-activate btn-fixed" @click="activateInternalOffer()">
              {{ offer.link_button_title }}
            </div>

            <div class="btn btn-activate btn-fixed" v-else-if="offer.closed || answeredSurvey"
                @click="goBackToGoodiebag()">
              {{ backToText }}
            </div>

            <div class="btn btn-activate btn-fixed" v-else-if="hasCTA" @click="setLoginView()">
              <!--internalLinkModal = true-->
              {{ offer.link_button_title || transl.Offer.gotooffer }}
            </div>
          </div>

          <modal v-if="internalLinkModal" class="modal" @close="internalLinkModal = false"
                :header="{text: offer.first_header, textPosition: 'center', bgColor: '#F2F2F2'}"
                slotTransName="slot" slotTransMode="out-in"
                :slotTransAppear="true" modalType="bottom">
            <slot>
                <div v-if="offer.closed">
                  <div class="competition-info">
                    <div class="internal-link-title-2">
                      {{ transl.Offer.expired }}
                    </div>

                    <div class="internal-link-title-4" style="text-align: center;">
                      {{ transl.Offer.keepeye }}
                    </div>

                    <div class="btn btn-activate" @click="goBackToGoodiebag()">
                      {{ backToText }}
                    </div>
                  </div>
                </div>

                <div v-else-if="offer.jwt_lock && offer.jwt_lock_explanation">
                  <iconComponent symbol="lock" :width="75" :stroke-width="1" fill="#EA7801" stroke="#EA7801" />

                  <div class="internal-link-title-4" v-html="offer.jwt_lock_explanation"></div>

                  <div
                    class="btn btn-activate"
                    @click="offer.jwt_lock_button_url ? openUrl(offer.jwt_lock_button_url) : gotoCollection()">
                    {{ offer.jwt_lock_button_text ? offer.jwt_lock_button_text : backToText }}
                  </div>
                </div>

                <div v-else-if="offer.pdf">
                  <vuePdf :page="pageNr" :src="`${windowOrigin}/api/nar/file/${offer.pdf}`"
                          @num-pages="pageCount = $event">
                  </vuePdf>

                  <div class="pdf-viewer-buttons">
                    <div class="btn btn-activate" @click="changePage(pageNr-1)">
                      <iconComponent symbol="arrowLeft" :width="30" />
                    </div>

                    <div class="btn btn-activate" @click="changePage(pageNr+1)">
                      <iconComponent symbol="arrowRight" :width="30" />
                    </div>

                    <div class="btn btn-activate" @click="openUrl(`${windowOrigin}/api/nar/file/${offer.pdf}`, true)">
                      <iconComponent symbol="magnifyingGlass" :width="30" />
                    </div>
                  </div>
                </div>

                <div v-else-if="offer.links_gallery && offer.links_gallery.links && offer.links_gallery.links.length">
                  <div class="competition-info btn-fixed-bg">
                    <div class="internal-link-title-2 selectable-text align-left" v-html="offer.links_gallery.label">
                    </div>

                    <div class="modal-external-links">
                      <div class="modal-external-link"
                        v-for="(hyperlink, index) in offer.links_gallery.hyperlinks"
                        :key="hyperlink._id">
                        <img
                          :src="hyperlink.media?.url"
                          @click="gotoExternalLink(offer.links_gallery.links[index])" />
                      </div>
                    </div>

                    <div class="btn btn-activate btn-fixed" @click="internalLinkModal = false">
                      {{ transl.Offer.back }}
                    </div>
                  </div>
                </div>

                <div v-else>
                  <div class="competition-info">
                    <iconComponent class="svg-icon" symbol="gift"/>


                    <div class="internal-link-title-2">
                      {{ transl.Offer.ops }}
                    </div>

                    <div class="internal-link-title-4" style="text-align: center;">
                      {{ transl.Offer.loginto }}
                      <div v-if="!isMobile && offer.clearon_id">
                        {{ transl.Offer.usedata }}
                      </div>
                    </div>

                    <div v-if="!offer.winner_adress && !offer.recurring_time" class="internal-link-title-1"
                        style="padding: 1rem;">
                      {{ transl.Offer.enterenginio }}
                    </div>

                    <div class="btn btn-activate btn-fixed" @click="setLoginView()">
                      {{ transl.Offer.login }}
                    </div>
                  </div>
                </div>
            </slot>
          </modal>
        </div>

        <div v-if="sideOffers && sideOffers.length" class="sideoffers-header-mobile">
          {{ transl.Offer.moreoffers }}
          <br />
          {{ transl.Offer.from }} {{ goodiebag.company.name }}
        </div>

        <div class="sideoffers-wrapper" v-if="sideOffers && sideOffers.length">
          <div class="sideoffers-header">
            {{ transl.Offer.moreoffers }}
            <br />
            {{ transl.Offer.from }} {{ goodiebag.company.name }}
          </div>

          <div class="sideoffer-box" :class="{ 'sideoffer-box-no-text-box': sideOffer.hide_text_box }"
              v-for="sideOffer in sideOffers"
              :key="sideOffer._id" @click="gotoCurrentOffer(sideOffer)">
            <div v-if="sideOffer.thumbnail && sideOffer.thumbnail.fileType && sideOffer.thumbnail.fileType.includes('image')"
                class="sideoffer-box-pic" :style="`background-image: url(${sideOffer.thumbnail.url});`">
            </div>

            <div v-else-if="sideOffer.media && sideOffer.media[0] && sideOffer.media[0].fileType.includes('image')"
                class="sideoffer-box-pic"
                :style="`background-image: url(${sideOffer.media && sideOffer.media[0] ? sideOffer.media[0].url : '' });`">
            </div>

            <video v-else-if="sideOffer.media && sideOffer.media[0] && sideOffer.media[0].fileType.includes('video')"
                  class="news-autoplay autoplayMedia"
                  :style="!sideOffer.hide_text_box ? 'border-radius: 6px 6px 0 0;' : 'border-radius: 6px;'"
                  :controls="false" muted playsinline>
              <source :src="`${sideOffer.media && sideOffer.media[0] ? sideOffer.media[0].url : '' }`"
                      :type="sideOffer.media[0].fileType">
              {{ transl.Offer.novideo }}
            </video>

            <!--<img TODO: enable
              v-if="sideOffer.icon?.url && sideOffer.unique_icon"
              :src="`${sideOffer.icon?.url}`"/>-->

            <div class="card-tag">
              <md-chip class="card-chip" v-if="sideOffer.offer_tag && sideOffer.offer_tag.length">
                {{ sideOffer.offer_tag }}
              </md-chip>
            </div>

            <div v-if="!sideOffer.hide_text_box" class="sideoffer-box-text">
              <div class="sideoffer-box-name body-1-bold">{{ sideOffer.first_header }}</div>
              <div v-if="sideOffer.variations" class="sideoffer-box-info body-2">
                <iconComponent symbol="style" />
                <span>{{ transl.Offer.variants }}</span>
              </div>

              <div v-else-if="sideOffer.save_text" class="sideoffer-box-info body-2">
                <iconComponent symbol="newReleases" />
                <span>{{ sideOffer.save_text }}</span>
              </div>

              <div v-else class="sideoffer-box-info body-2">
                <span style="padding: 0;">{{ sideOffer.quick_text }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="expired-date-offer" v-else-if="expiresAtState === 'expired' && !expiredOffer && loadedPage">
      <div>
        <iconComponent symbol="timeOut" :width="150" :stroke-width="0" fill="#EA7801" />

        <div class="expired-date-header">{{ transl.Offer.expired }}</div>
        <div class="expired-date-text">{{ transl.Offer.watchout }}</div>

        <div class="btn btn-activate" @click="goBackToGoodiebag()">
          {{ backToText }}
        </div>
      </div>
    </div>

    <modal v-if="tocModal" @close="tocModal = false" size="medium" padding="0" :terms="true">
      <slot>
        <div class="terms-wrapper">
          <div v-if="offer.terms_text" v-html="offer.terms_text" class="html-text"></div>

          <div class="terms-buttons">
            <div
              v-if="offer.terms_merchandise && !acceptTerms && !requiresLogin"
              class="terms-merchandise-grid">
              <inputField
                class="terms-merchandise-checkbox"
                v-model="acceptMerchandise"
                inputType="checkbox"/>
              <span
                class="terms-merchandise-text"
                v-html="offer.terms_merchandise"></span>
            </div>

            <div v-if="!acceptTerms && !requiresLogin" class="btn btn-activate" @click="setAcceptTerms()">
              {{ transl.UserPV.accept }}
            </div>

            <div class="btn btn-secondary" @click="tocModal = false">
              {{ transl.UserPV.close }}
            </div>
          </div>
        </div>
      </slot>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { isMobile } from 'mobile-device-detect';
import VueSlider from 'vue-slider-component';
import vuePdf from 'vue-pdf';
// import videojs from 'video.js';
// import EventBus from '../resources/eventBus';
import aCarousel from '../components/ACarousel.vue';
import ExpiredMixin from '../mixins/ExpiredMixin.vue';
import EntityMixin from '../mixins/EntityMixin.vue';
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';
import AnnoyingClientMixin from '../mixins/AnnoyingClientMixin.vue';
import InputField from '../stories/components/InputField/InputField.vue';

export default {
  name: 'HomeOffer',
  components: {
    aCarousel,
    vuePdf,
    VueSlider,
    InputField,
  },
  mixins: [ExpiredMixin, BeforeEnterMixin, AnnoyingClientMixin, EntityMixin],
  props: ['offerId'],
  mounted() {
    if (!this.offerId) {
      this.$router.replace({ name: 'Home' });
    } else if (this.authenticated) {
      this.$router.replace({
        name: 'UserOffer',
        params: { offerId: this.offerId },
      });
    } else {
      this.$store
        .dispatch('getNarOffer', {
          offerId: this.offerId,
        })
        .then(() => {
          this.getAlreadyActivated();
          this.expiresAtState = this.getExpiredState(this.offer);
          this.$store
            .dispatch('getNarGoodiebag', {
              goodiebagId: this.offer.goodiebag._id,
            })
            .then(() => {
              this.setPageTitle(this.goodiebag.company.name, this.offer.first_header);
              this.pushDataLayer({
                parentCompany: this.goodiebag.company.parentCompany,
                company: this.goodiebag.company,
                goodiebag: this.goodiebag,
                offer: this.offer,
                action: 'Enter',
              });
              this.$store
                .dispatch('getNarCompany', {
                  companyId: this.goodiebag.company._id,
                })
                .then(() => {
                  // this.$dpAnalytics({ view: 'HomeOffer', subject: this.offer._id, action: 'enter' });
                  // this.$dpAnalytics({ view: 'HomeOffer', subject: this.goodiebag._id, action: 'enter offer inside collection' });
                  // this.$analytics.fbq.event('ViewContent', { content_name: `offer ${this.offerId}` });
                });
              this.$store.dispatch('parentCompanies/getNarParentCompany', {
                parentCompanyId:
                  this.goodiebag.company.parentCompany._id ||
                  this.goodiebag.company.parentCompany,
              });

              this.handleTerms();

              this.loadedPage = true;
              this.setLoadedPageData(true);

              if (!this.goodiebag.company.login_mandatory
                && this.offer.jwt_lock && this.offer.jwt_lock_explanation) {
                this.internalLinkModal = true;
              }

              if (this.offer.iframe_website) {
                window.addEventListener(
                  'message',
                  (event) => {
                    // console.log('enginio - iframe_website', this.offer.iframe_website);
                    // console.log('enginio - event.origin', event.origin);
                    if (this.offer.iframe_website.includes(event.origin)) {
                      const { modal, back, snackbar } = event.data;
                      // console.log('enginio - event.data.modal', modal);
                      // console.log('enginio - event.data.back', back);
                      // console.log('enginio - event.data.snackbar', snackbar);
                      if (modal === 'support') {
                        this.$emit('openSupportModal', {});
                      } else if (modal === 'login') {
                        this.$emit('setLoginView', {});
                      } else if (modal === 'terms') {
                        this.tocModal = !this.tocModal;
                      } else if (back === 'collection') {
                        this.goBackToGoodiebag();
                      } else if (snackbar && snackbar.message) {
                        this.setAlert(snackbar.message, snackbar.seconds);
                      }
                    }
                  },
                  false
                );
              }
              if (this.offer.closed) {
                this.internalLinkModal = true;
              }
              setTimeout(() => {
                let elements = document.getElementsByClassName('autoplayMedia');
                for (let i = 0; i < elements.length; i += 1) {
                  // if (this.isAnnoyingClient) {
                  //   // dont autoplay
                  // } else {
                  this.autoplay(elements[i]);
                  // }
                }
              }, 200);
            });
        });
    }
  },
  watch: {
    offerId(newVal, oldVal) {
      if (newVal !== oldVal) {
        location.reload();
      }
    },
  },
  computed: {
    ...mapGetters([
      'user',
      'userStatus',
      'authenticated',
    ]),
    expiresDate() {
      return this.getExpiresDate(this.offer);
    },
    hasCTA() {
      if (this.offer && this.offer._id) {
        if (this.offer.pdf) {
          return true;
        } else if (this.offer.survey && this.offer.survey.length) {
          return true;
        } else if (this.offer.recurring_time) {
          return true;
        } else if (this.offer.winner_adress) {
          return true;
        } else if (this.offer.internal_link) {
          return true;
        } else if (this.offer.external_link) {
          return true;
        } else if (this.offer.links_gallery && this.offer.links_gallery.label) {
          return true;
        } else if (this.offer.clearon_id || this.offer.clearon_ean_id) {
          return true;
        } else if (this.offer.cta_email) {
          return true;
        } else if (this.offer.iframe_website) {
          return true;
        }
      }
      return false;
    },
    sideOffers() {
      if (this.goodiebag.offers && this.goodiebag.offers.length) {
        let el = this;
        const excludeCurrentOffer = this.goodiebag.offers.filter(function (
          offer
        ) {
          return (
            offer._id !== el.offer._id &&
            !offer.closed &&
            offer.clickable &&
            offer.unlocked &&
            (offer.media.length > 0 || Boolean(offer.thumbnail?.url))
          );
        });
        let indexes = [0, 1, 2];
        if (excludeCurrentOffer.length > 3) {
          indexes = [];
          while (indexes.length < 3) {
            let r =
              Math.floor(Math.random() * excludeCurrentOffer.length - 1) + 1;
            if (indexes.indexOf(r) === -1) {
              indexes.push(r);
            }
          }
        }
        const sideOffers = excludeCurrentOffer.filter(function (offer, index) {
          return indexes.indexOf(index) !== -1;
        });
        return this.shuffle(sideOffers);
      } else {
        return [];
      }
    },
    backToText() {
      return `${this.transl.Offer.back} ${
        this.goodiebag.offers && this.goodiebag.offers.length === 1
          ? ` ${this.transl.Offer.tohome}`
          : ''
      }`;
    },
    iframeWebsiteUrl() {
      let iframeWebsiteUrl = '';
      if (this.offer && this.offer.iframe_website) {
        if (this.offer.iframe_website.includes('?')) {
          iframeWebsiteUrl = `${this.offer.iframe_website}&relayId=${this.$route.query.relayId}`;
        } else {
          iframeWebsiteUrl = `${this.offer.iframe_website}?relayId=${this.$route.query.relayId}`;
        }
      } else {
        iframeWebsiteUrl = false;
      }
      return iframeWebsiteUrl;
    },
    requiresLogin() {
      if (this.offer && this.offer._id) {
        return (this.offer.must_login ||
          this.offer.winner_adress ||
          this.offer.cta_email ||
          this.offer.recurring_time ||
          this.offer.clearon_id ||
          this.offer.clearon_ean_id) ? true : false;
      } else {
        return true;
      }
    },
    hasNotAcceptedRequiredTerms() {
      return !this.requiresLogin && !this.acceptTerms && this.offer.terms_must_accept;
    },
  },
  data() {
    return {
      currentUrl: location.href,
      internalLinkModal: false,
      firstTime: true,
      expiredOffer: false,
      answeredSurvey: false,
      surveyAnswers: {},
      surveyOther: {},
      expiresAtState: '',
      isMobile,
      pageCount: 0,
      pageNr: 1,
      loadedPage: false,
      tocModal: false,
      acceptTerms: false,
      acceptMerchandise: true,
    };
  },
  /*
  el.players.push(videojs(this.$refs[`videoPlayer${i}`], {}, function onPlayerReady() {
    console.log('onPlayerReady', this);
  }));

  beforeDestroy() {
    for (let i = 0; i < this.players.length; i += 1) {
      this.players[i].dispose();
    }
  },*/
  destroyed() {
    document.removeEventListener('message', () => {});
    this.$store.dispatch('resetOffer');
  },
  methods: {
    /*
    createShareLink() {
      let shareLink = document.getElementById('share-link')
      shareLink.setAttribute('type', 'text');
      shareLink.select();

      let successful = true;
      try {
        document.execCommand('copy');
      } catch (err) {
        successful = false;
      }
      shareLink.setAttribute('type', 'hidden');
      window.getSelection().removeAllRanges();

      if (successful) {
        this.setAlert(this.transl.App.copy);
      } else {
        this.setAlert(this.transl.App.nocopy);
      }
    },
    */
    shuffle(a) {
      let j, x, i;
      for (i = a.length - 1; i > 0; i--) {
        j = Math.floor(Math.random() * (i + 1));
        x = a[i];
        a[i] = a[j];
        a[j] = x;
      }
      return a;
    },
    onCopy() {
      /*this.$dpAnalytics({
        view: 'HomeOffer',
        subject: this.offer._id,
        action: 'share',
      });*/
      this.pushDataLayer({
        parentCompany: this.offer.company
          ? this.offer.company.parentCompany
          : 'Unkown',
        company: this.goodiebag.company,
        goodiebag: this.offer.goodiebag,
        offer: this.offer,
        action: 'Share',
      });
      this.setAlert(this.transl.App.copy);
    },
    onError() {
      this.setAlert(this.transl.App.nocopy);
    },
    gotoExternalLink(url) {
      // this.$dpAnalytics({ view: 'HomeOffer', subject: this.offer._id, action: `activate link ${url}` });
      this.pushDataLayer({
        parentCompany: this.offer.company
          ? this.offer.company.parentCompany
          : 'Unkown',
        company: this.goodiebag.company,
        goodiebag: this.offer.goodiebag,
        offer: this.offer,
        action: `Activate link ${url}`,
      });
      localStorage.setItem('previousOffer', this.offer._id);
      this.openUrl(url);
    },
    goBackToGoodiebag() {
      /*if (this.goodiebag.offers.length === 1) {
        this.$router.push({ name: 'Home' });
      } else {
      }*/
      this.$router.push({
        name: 'HomeGoodiebag',
        params: { goodiebagId: this.getId(this.offer.goodiebag) },
      });
    },
    setLoginView() {
      this.internalLinkModal = false;
      let el = this;
      setTimeout(function () {
        el.$emit('setLoginView', {});
      }, 10); // 500
    },
    gotoCollection() {
      this.$router.push({
        name: 'HomeGoodiebag',
        params: { goodiebagId: this.getId(this.offer.goodiebag) },
      });
    },
    gotoCurrentOffer(offer) {
      location.assign(`${this.windowOrigin}/a/${this.getId(offer)}`);
    },
    changePage(newPageNr) {
      if (newPageNr <= this.pageCount && newPageNr > 0) {
        this.pageNr = newPageNr;
      }
    },
    getAlreadyActivated() {
      if (this.offer && this.offer._id) {
        const activated = localStorage.getItem(`activated-${this.offer._id}`);
        const alreadyActivated = activated ? true : false;
        if (this.offer.survey && this.offer.survey.length) {
          this.answeredSurvey = alreadyActivated;
        } else {
          console.log('ERROR: cta not supported yet');
        }
      }
    },
    activateInternalOffer(getExit, eanCode, link) {
      /*this.$dpAnalytics({
        view: 'HomeOffer',
        subject: this.offer._id,
        action: 'activate',
      });*/
      if (this.hasNotAcceptedRequiredTerms) {
        this.tocModal = true;
        return;
      }
      if (this.surveyAnswers && Object.keys(this.surveyAnswers).length) {
        let { success, message } = this.checkRequiredQuestions();
        if (!success) {
          this.setAlert(message, 3);
          return;
        }
      }
      this.pushDataLayer({
        parentCompany: this.offer.company
          ? this.offer.company.parentCompany
          : 'Unkown',
        company: this.goodiebag.company,
        goodiebag: this.offer.goodiebag,
        offer: this.offer,
        action: 'Activate',
      });
      this.$store
        .dispatch('activateHomeOffer', {
          offerId: this.offer._id,
          answers: this.surveyAnswers,
          acceptMerchandise: this.acceptMerchandise,
          eanCode,
          getExit,
        })
        .then((/*{
        interleaved_code = false,
        ean_link,
        winner_adress = false,
        cta_email = false,
        success,
        no_cta,
      }*/) => {
          /*this.$dpAnalytics({
            view: 'HomeOffer',
            subject: this.offer._id,
            action: 'won',
          });*/
          this.pushDataLayer({
            parentCompany: this.offer.company
              ? this.offer.company.parentCompany
              : 'Unkown',
            company: this.goodiebag.company,
            goodiebag: this.offer.goodiebag,
            offer: this.offer,
            action: 'Won',
          });
          this.activateErrorMsg = null;
          this.activateErrorButton = 'retry';
          if (this.offer.survey && this.offer.survey.length) {
            this.answeredSurvey = true;
            this.setAlert(this.transl.Offer.thanks);
            localStorage.setItem(`activated-${this.offer._id}`, true);
            /*} else if (interleaved_code) {
        } else if (ean_link) {
        } else if (winner_adress) {
        } else if (success && this.offer.recurring_time) {
        } else if (cta_email) {
        } else if (no_cta) {*/
          } else {
            this.setAlert(this.transl.routes.serverissue);
          }
        })
        .catch((err) => {
          const { message } = err.response.data;
          /*this.$dpAnalytics({
            view: 'HomeOffer',
            subject: this.offer._id,
            action: 'lose',
          });*/
          this.pushDataLayer({
            parentCompany: this.offer.company
              ? this.offer.company.parentCompany
              : 'Unkown',
            company: this.goodiebag.company,
            goodiebag: this.offer.goodiebag,
            offer: this.offer,
            action: 'Lose',
          });
          /*let activateError = message;
        if (error === 'SUPPORT') {
          activateError = 'support';
        } else if (error === 'EMPTY') {
          activateError = 'goodiebag';
        } else if (error === 'TIME') {
          activateError = 'retry';
        } else if (error === 'USED') {
          if (link) {
            this.gotoExternalLink(link);
          } else {
            activateError = 'goodiebag';
          }
        } else if (error === 'EARLY_TIME') {
          activateError = 'tooEarly';
        }
        if (!link) {
          this.showRecurringVideo(false, activateError, message);
        }*/
          if (!this.offer.survey || !this.offer.survey.length || link) {
            this.setAlert('', 0); // removes alert from axios response-interceptor
          } else {
            this.setAlert(message, 3);
          }
        });
    },
    checkRequiredQuestions() {
      let unfilled = false;
      let message = '';
      for (let i = 0; i < this.offer.survey.length; i++) {
        let question = this.offer.survey[i];
        if (question.required) {
          let answeredRequiredField = false;
          if (question.upload) {
            if (this.surveyUpload && this.surveyUpload.data) {
              answeredRequiredField = true;
            }
          } else {
            const objectKeys = Object.keys(this.surveyAnswers);
            for (let j = 0; j < objectKeys.length; j++) {
              const objectKey = objectKeys[j];
              if (objectKey === question.model && this.surveyAnswers[objectKey]) {
                answeredRequiredField = true;
                break;
              }
            }
          }
          if (!answeredRequiredField) {
            message += `${i+1}, `;
            unfilled = true;
          }
        }
      }
      if (unfilled) {
        message.slice(message.length - 2);
        message = `Question(s) ${message} isn't answered even though it's required`;
        return { success: false, message };
      } else {
        return { success: true };
      }
    },
    setCheckbox(question, option, value, notCheck) {
      if (question.checkbox && question.options && question.options.length) {
        if (
          !this.surveyAnswers[question.model] ||
          !this.surveyAnswers[question.model].length
        ) {
          this.surveyAnswers[question.model] = [];
        }
        const isOther = option == 'other';
        let isChecked = -1;
        for (let i = 0; i < this.surveyAnswers[question.model].length; i++) {
          const internalOption = this.surveyAnswers[question.model][i];
          if (option === internalOption) {
            isChecked = i;
          } else if (isOther && this.surveyOther[question.model] == internalOption) {
            isChecked = i;
          }
        }
        if (notCheck) {
          isChecked = -1;
          this.removeOldOther(question, value);
        }
        if (isChecked < 0) {
          if (isOther) {
            const otherOptionCheckbox = document.getElementById(`${question.model}other`);
            otherOptionCheckbox.checked = true;
            this.surveyAnswers[question.model].push(this.surveyOther[question.model]);
          } else {
            this.surveyAnswers[question.model].push(option);
          }
        } else {
          this.surveyAnswers[question.model].splice(isChecked, 1);
        }
      }
    },
    setRadio(question, option, notCheck) {
      const isOther = option == 'other';
      if (isOther) {
        const otherOptionRadio = document.getElementById(`${question.model}other`);
        if (notCheck) {
          otherOptionRadio.checked = true;
        } else {
          otherOptionRadio.checked = !otherOptionRadio.checked;
        }
        if (otherOptionRadio.checked) {
          this.surveyAnswers[question.model] = this.surveyOther[question.model];
        }
      }
    },
    removeOldOther(question, newValue) {
      for (let i = 0; i < this.surveyAnswers[question.model].length; i++) {
        const internalOption = this.surveyAnswers[question.model][i];
        let remove = true;
        for (let j = 0; j < question.options.length; j++) {
          const option = question.options[j];
          if (option == internalOption && internalOption != newValue) {
            remove = false;
          }
        }
        if (remove) {
          this.surveyAnswers[question.model].splice(i, 1);
        }
      }
    },
    getAcceptedTerms() {
      if (this.requiresLogin) {
        return false;
      } else {
        return localStorage.getItem(`accepted-terms-${this.offer._id}`) == 'true';
      }
    },
    getAcceptedMerchandise() {
      if (this.requiresLogin) {
        return false;
      } else {
        if (!this.acceptTerms) {
          return this.offer.terms_merchandise_default;
        } else {
          return localStorage.getItem(`accepted-merchandise-${this.offer._id}`) == 'true';
        }
      }
    },
    handleTerms() {
      if (this.offer && this.offer._id) {
        this.acceptTerms = this.getAcceptedTerms();
        this.acceptMerchandise = this.getAcceptedMerchandise();
        if (this.hasNotAcceptedRequiredTerms) {
          this.tocModal = true;
        }
      } else {
        console.error('Did not handle terms!');
      }
    },
    setAcceptTerms() {
      this.acceptTerms = true;
      localStorage.setItem(`accepted-terms-${this.offer._id}`, true);
      localStorage.setItem(`accepted-merchandise-${this.offer._id}`, this.acceptMerchandise);
      this.tocModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/pages/HomeOffer";
</style>
