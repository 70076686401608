import axios from 'axios';

export default {
  // Admin
  getAdminPVActivation: (entityId) => {
    return axios.get(`/api/pv/admin/activations/${entityId}`)
      .then(response => {
        return {
          activation: response.data.activation != null
            ? response.data.activation : {},
          }});
  },

  findAdminAllReceipts: (data) => {
    return axios.put('/api/pv/admin/allreceipts', data)
      .then(response => {
        return {
          receipts: response.data.receipts != null
            ? response.data.receipts : [],
          receiptsTotal: response.data.receiptsTotal != null
            ? response.data.receiptsTotal : 0,
          }});
  },

  updateAdminReceiptByKey: (data) => {
    return axios.put(`/api/pv/admin/receipts/${data.receiptId}/bykey`, data)
      .then(response => {
        return {
          receipt: response.data.receipt != null
            ? response.data.receipt : {},
          }});
  },

  getAdminReceipt: (data) => {
    return axios.get(`/api/pv/admin/receipts/${data.receiptId}`)
      .then(response => {
        return {
          receipt: response.data.receipt != null
            ? response.data.receipt : {},
          }});
  },

  confirmAdminReceipt: (payload) => {
    return axios.put(`/api/pv/admin/receipts/${payload.entityId}/confirm`, payload)
      .then(response => ({
        receipt: response.data.receipt != null
          ? response.data.receipt : {},
      }));
  },
  confirmAdminRegistration: (payload) => {
    return axios.put(`/api/pv/admin/receipts/${payload.receiptId}/registration/confirm`, payload)
      .then(response => ({
        receipt: response.data.receipt != null
          ? response.data.receipt : {},
      }));
  },

  addAdminReceiptComment: (payload) => {
    return axios.put(`/api/pv/admin/receipts/${payload.entityId}/comment`, payload)
      .then(response => ({
        receipt: response.data.receipt != null
          ? response.data.receipt : {},
      }));
  },

  sendCtaEmail: (entityId) => {
    return axios.put(`/api/pv/admin/receipts/${entityId}/send-cta-email`)
    .then(response => ({
      message: response.data != null
        ? response.data : '',
    }));
  },

  confirmAdminCashback: (payload) => {
    return axios.put(`/api/pv/admin/receipts/${payload.entityId}/cashback/confirm`, payload)
      .then(response => ({
        receipt: response.data.receipt != null
          ? response.data.receipt : {},
      }));
  },

  addAdminReceiptDelayedCtaBlock: (payload) => {
    return axios.put(`/api/pv/admin/receipts/${payload.entityId}/delayedcta/block`, payload)
      .then(response => ({
        receipt: response.data.receipt != null
          ? response.data.receipt : {},
      }));
  },

  saveAdminReceipt: (payload) => {
    return axios.put(`/api/pv/admin/receipts/${payload.receiptId}`, payload)
      .then(response => ({
        receipt: response.data.receipt != null
          ? response.data.receipt : {},
      }));
  },

  cloneReceipt: data => {
    return axios.post(`/api/pv/admin/receipts/${data.receiptId}/clone/`).then(response => {
      return {
        clone: response.data != null ? response.data : []
      };
    });
  },

  deleteAdminReceipt: (payload) => {
    return axios.delete(`/api/pv/admin/receipts/${payload.receiptId}?deleteCode=${payload.deleteCode}`)
      .then(response => ({
        message: response.data.message != null
          ? response.data.message : '',
      }));
  },

  testPVActions: (payload) => {
    return axios.post(`/api/pv/admin/receipts/${payload.receiptId}/actions`, payload)
      .then(response => ({
        message: response.data.message != null
          ? response.data.message : '',
      }));
  },

  forcePVAction: (payload) => {
    return axios.post(`/api/pv/admin/receipts/${payload.receiptId}/actions/${payload.actionId}`, payload)
      .then(response => ({
        message: response.data.message != null
          ? response.data.message : '',
      }));
  },
};
