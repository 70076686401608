<template>
  <div v-if="loadedPage" class="admin-content--container">
    <div class="admin-search-container">
      <div class="admin-spacer"/>
      
      <div class="add-entity-button" @click="showOfferDialog = true">
        <iconComponent symbol="circledPlus" />
      </div>
    </div>

    <aList
      entityType="Collections"
      columnsWidth="400"
      :entities="offers"
      :keys="offerKeys"
      routerName="AdminOffer"
      routerId="offerId"/>

    <modal v-if="showOfferDialog" size="xlarge"
          @close="showOfferDialog = false;" modalType="fullscreen">
      <slot>
        <EntityEditor
          entity-type="Offer"
          :admin="true"/>
      </slot>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import EventBus from "../resources/eventBus";
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';
import aList from "../components/AList";
import EntityEditor from '../stories/components/EntityEditor/EntityEditor.vue';

export default {
  name: "AdminOffers",
  components: {
    aList,
    EntityEditor,
  },
  mixins: [BeforeEnterMixin],
  mounted() {
    this.findAdminOffers();
    this.setPageTitle('Admin', 'Activations');
  },
  destroyed() {
    EventBus.$emit('selected', '');
  },
  computed: {
    ...mapGetters(["offer", "offers", "userStatus"])
  },
   data() {
    return {
      showOfferDialog: false,
      loadedPage: false,
      offerKeys: [
        {
          title: 'Id',
          key: '_id'
        },
        {
          title: 'First Header',
          key: 'first_header'
        },
        {
          title: 'Unique Name',
          key: 'unique_name'
        },
        {
          title: 'Public',
          key: 'public',
          style: [{ color: 'red', value: false }, { color: 'green', value: true }],
        },
        {
          title: 'Company',
          key: 'company.name'
        },
        {
          title: 'Goodiebag',
          key: 'goodiebag.name'
        },
        {
          title: 'Public',
          key: 'public'
        },
      ],
    };
  },
  methods: {
    findAdminOffers(query) {
      this.$store.dispatch('findAdminOffers', { query }).then(() => {
        this.loadedPage = true;
      });
    },
    addOfferChild(data) {
      this.parentOfferId = data.offerId;
      this.showOfferDialog = true;
    },
    openOfferDialog(data) {
      this.offerId = data.offerId;
      this.showOfferDialog = true;
    },
    closeOfferDialog() {
      this.parentOfferId = false;
      this.showOfferDialog = false;
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/scss/pages/AdminOffers";
</style>