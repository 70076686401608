import axios from 'axios';

export default {
  // Admin
  findAdminBlocked: (data) => {
    return axios.put('/api/pv/admin/blocked', data)
      .then(response => {
        return {
          blocked: response.data.blocked != null
            ? response.data.blocked : [],
        }
      });
  },

  addAdminBlock: (data) => {
    return axios.post('/api/pv/admin/blocked', data)
      .then(response => {
        return {
          block: response.data.block != null
            ? response.data.block : {},
        }
      });
  },

  deleteAdminBlock: (blockId) => {
    return axios.delete(`/api/pv/admin/blocked/${blockId}`)
      .then(response => ({
        message: response.data.message != null
          ? response.data.message : '',
      }));
  },
};
