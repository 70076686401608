import axios from 'axios';

export default {
  // Admin
  findAdminGoodiebags: (data) => {
    return axios.put('/api/admin/goodiebags', data)
      .then(response => {
        return { goodiebags: response.data.goodiebags != null
          ? response.data.goodiebags : []}
        });
  },

  getAdminGoodiebag: (payload) => {
    return axios.get(`/api/admin/goodiebags/${payload.entityId}`)
      .then(response => ({
        goodiebag: response.data.goodiebag != null
          ? response.data.goodiebag : {},
      }));
  },

  addAdminGoodiebag: (payload) => {
    return axios.post('/api/admin/goodiebags', payload)
      .then(response => ({
        goodiebag: response.data.goodiebag != null
          ? response.data.goodiebag : {},
      }));
  },

  addAdminGoodiebagMedia: (payload) => {
    return axios.put(`/api/admin/goodiebags/${payload.entityId}/media/${payload.mediaType}`, payload.data, payload.config)
      .then(response => ({
        goodiebag: response.data.goodiebag != null
          ? response.data.goodiebag : {},
      }));
  },

  finishAdminGoodiebagLinks: (payload) => {
    return axios.put(`/api/admin/goodiebags/${payload.entityId}/links-done`)
      .then(response => ({
        goodiebag: response.data.goodiebag != null
          ? response.data.goodiebag : {},
      }));
  },

  saveAdminGoodiebag: (payload) => {
    return axios.put(`/api/admin/goodiebags/${payload.entityId}`, payload)
      .then(response => ({
        goodiebag: response.data.goodiebag != null
          ? response.data.goodiebag : {},
      }));
  },

  publiciseAdminGoodiebag: (payload) => {
    return axios.put(`/api/admin/goodiebags/${payload.entityId}/publicise`, payload)
      .then(response => ({
        goodiebag: response.data.goodiebag != null
          ? response.data.goodiebag : {},
      }));
  },

  publiciseLandingAdminGoodiebag: (payload) => {
    return axios.put(`/api/admin/goodiebags/${payload.entityId}/publicise-landing`, payload)
      .then(response => ({
        goodiebag: response.data.goodiebag != null
          ? response.data.goodiebag : {},
      }));
  },

  archiveAdminGoodiebag: (payload) => {
    return axios.put(`/api/admin/goodiebags/${payload.entityId}/archive`, payload)
      .then(response => ({
        goodiebag: response.data.goodiebag != null
          ? response.data.goodiebag : {},
      }));
  },

  deleteAdminGoodiebagPictures: (payload) => {
    return axios.delete(`/api/admin/goodiebags/${payload.entityId}/picture`)
      .then(response => ({
        goodiebag: response.data.goodiebag != null
          ? response.data.goodiebag : {},
      }));
  },

  deleteAdminGoodiebag: (goodiebagId) => {
    return axios.delete(`/api/admin/goodiebags/${goodiebagId}`)
      .then(response => ({
        message: response.data.message != null
          ? response.data.message : '',
      }));
  },

  // Company
  findCompanyGoodiebags: (data) => {
    return axios.put('/api/c/goodiebags', data)
      .then(response => {
        return { goodiebags: response.data.goodiebags != null
          ? response.data.goodiebags : []}
        });
  },

  getCompanyGoodiebag: (payload) => {
    return axios.get(`/api/c/goodiebags/${payload.entityId}`)
      .then(response => ({
        goodiebag: response.data.goodiebag != null
          ? response.data.goodiebag : {},
      }));
  },

  addCompanyGoodiebag: (payload) => {
    return axios.post('/api/c/goodiebags', payload)
      .then(response => ({
        goodiebag: response.data.goodiebag != null
          ? response.data.goodiebag : {},
      }));
  },

  addCompanyGoodiebagMedia: (payload) => {
    return axios.put(`/api/c/goodiebags/${payload.entityId}/media/${payload.mediaType}`, payload.data, payload.config)
      .then(response => ({
        goodiebag: response.data.goodiebag != null
          ? response.data.goodiebag : {},
      }));
  },

  saveCompanyGoodiebag: (payload) => {
    return axios.put(`/api/c/goodiebags/${payload.entityId}`, payload)
      .then(response => ({
        goodiebag: response.data.goodiebag != null
          ? response.data.goodiebag : {},
      }));
  },

  publiciseCompanyGoodiebag: (payload) => {
    return axios.put(`/api/c/goodiebags/${payload.entityId}/publicise`, payload)
      .then(response => ({
        goodiebag: response.data.goodiebag != null
          ? response.data.goodiebag : {},
      }));
  },

  archiveCompanyGoodiebag: (payload) => {
    return axios.put(`/api/c/goodiebags/${payload.entityId}/archive`, payload)
      .then(response => ({
        goodiebag: response.data.goodiebag != null
          ? response.data.goodiebag : {},
      }));
  },

  deleteCompanyGoodiebagPictures: (payload) => {
    return axios.delete(`/api/c/goodiebags/${payload.entityId}/picture`)
      .then(response => ({
        goodiebag: response.data.goodiebag != null
          ? response.data.goodiebag : {},
      }));
  },

  deleteCompanyGoodiebag: (goodiebagId) => {
    return axios.delete(`/api/c/goodiebags/${goodiebagId}`)
      .then(response => ({
        message: response.data.message != null
          ? response.data.message : '',
      }));
  },

  // Consumer
  getGoodiebag: (payload) => {
    return axios.get(`/api/goodiebags/${payload.goodiebagId}`)
      .then(response => ({
        goodiebag: response.data.goodiebag != null
          ? response.data.goodiebag : {},
      }));
  },
};
