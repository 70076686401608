<template>
  <div :class="checkMediaType()">
    <div class="uploader-container__header">Change {{ entity }} {{ mediaType }}</div>
    <div
			class="uploader-container"
      v-if="!edit">
      <form
        enctype="multipart/form-data"
        novalidate>
        <input
          :id="`uploadDropzone${entity}${entityId}${mediaType}`"
          type="file"
          accept="*"
          class="uploader__input-file"
          @change="inputFilter($event.target.files[0])">
        <label :for="`uploadDropzone${entity}${entityId}${mediaType}`">
          <img
            v-if="fileType.includes('image') && source"
            class="uploader__image"
            :src="source"
            :style="{ spinner: uploadStatus === 'UPLOADING' }">
          <video
            v-else-if="fileType.includes('video') && source"
            class="autoplay-box autoplayMedia uploader__video"
            :controls="false"
            muted playsinline>
            <source
              :src="source"
              :type="fileType">
            {{ transl.Offer.novideo }}
          </video>
          <div
            v-else-if="fileType === 'pdf' && source"
            class="pdf-wrapper">
            <vuePdf
              class="pdf-component"
              :page="pageNr"
              :src="`${windowOrigin}/api/nar/file/${source}`"
              @num-pages="pageCount = $event"></vuePdf>
          </div>
          <div v-if="mediaType != 'Pdf'" class="uploader__add-image">
            <p>Upload {{ mediaType }}</p>
            <iconComponent symbol="pencil" stroke="white" fill="white" width="16px"/>
          </div>
        </label>
      </form>
      <div v-if="fileType === 'pdf' && source" class="pdf-viewer-buttons">
        <div @click="gotoPage(-1)" class="uploader-edit__buttons-previous"><iconComponent symbol="arrowLeft" /><p>Previous page</p></div>
        <div @click="gotoPage(+1)" class="uploader-edit__buttons-forward"><iconComponent symbol="arrowRight" /><p>Next page</p></div>
      </div>
    </div>

    <div
      class="uploader-edit"
      v-else-if="file.url && edit">
      <div
        class="uploader-edit__picture"
        v-if="file.url">
        <img
          ref="editImage"
          :src="file.url"/>
      </div>
      <div class="uploader-edit__buttons-container">
        <div @click.prevent="resetEdit()"><iconComponent symbol="cross" />Cancel</div>
        <div @click.prevent="addOffer(true)"><iconComponent symbol="checkmark" /> Apply changes</div>
      </div>
    </div>
  </div>
</template>

<script>
import validator from 'validator';
import Cropper from 'cropperjs';
import vuePdf from 'vue-pdf';
import EventBus from '../../../resources/eventBus';

export default {
  name: 'UploadComponent',
  components: {
    vuePdf,
  },
  props: {
    media: {
      type: [String, Object, Array],
      required: false,
      default: () => {},
    },
    role: {
      type: String,
      required: false,
      default: 'Admin',
    },
    entity: {
      type: String,
      required: true,
    },
    entityId: {
      type: String,
      required: true,
    },
    mediaType: {
      type: String,
      required: true,
    },
    aspectWidth: {
      type: Number,
      required: false,
      default: 1,
    },
    aspectHeight: {
      type: Number,
      required: false,
      default: 1,
    },
    keepFeFile: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  computed: {
    disableSaveOffer() {
      return !this.file;
    },
    source() {
      if (Array.isArray(this.media) && this.media[0] && this.media[0].url) {
        return this.media[0].url;
      } else if (Array.isArray(this.media)) {
        return undefined;
      } else if (this.media && this.media.url) {
        return this.media.url;
      } else if (this.entity.includes('PV') && this.media && this.media.file) {
        return `${this.windowOrigin}/api/pv/nar/file/${this.media.file}`;
      } else if (typeof this.media === 'object') {
        return undefined;
      } else if (this.feSource) {
        return this.feSource;
      } else {
        return this.media;
      }
    },
    fileType() {
      if (Array.isArray(this.media) && this.media[0] && this.media[0].fileType) {
        return this.media[0].fileType;
      } else if (this.media && this.media.fileType) {
        return this.media.fileType;
      } else if (this.mediaType === 'Pdf') {
        return 'pdf';
      } else {
        return 'image';
      }
    },
    namespace() {
      let namespace = '';
      if (this.entity === 'ParentCompany') {
        namespace = 'parentCompanies/';
      } else if (this.entity.includes('Game')) {
        namespace = 'games/';
      } else if (this.entity.includes('PVActivation')) {
        namespace = 'pvActivations/';
      } else if (this.entity.includes('PVCompany')) {
        namespace = 'pvCompanies/';
      }
      return namespace;
    },
    userType() {
      return this.admin ? 'Admin' : 'Company';
    },
    vuexEntityType() {
      if (this.entity.includes('Game')) {
        return 'Game';
      } else {
        return this.entity;
      }
    },
    finalEntityType() {
      if (this.entity.includes('Game')) {
        return this.entity.split('Game')[0];
      } else {
        return this.entity;
      }
    },
  },
  mounted() {
    setTimeout(() => {
      let elements = document.getElementsByClassName("autoplayMedia");
      for (let i = 0; i < elements.length; i += 1) {
        // if (this.isAnnoyingClient) {
        //   // dont autoplay
        // } else {
            // this.autoplay(elements[i]);
        // }
      }
    }, 200);
    this.resizeCanvas();
  },
  data() {
    return {
      validator,
      file: {},
      dragOver: false,
      edit: false,
      cropper: false,
      fileSize: 10 * 1000000, // 10 MB
      uploadStatus: 'READY',
      pageCount: 0,
      pageNr: 1,
      feSource: '',
    };
  },
  /*watch: {
    edit(value) {
      if (value) {
        this.$nextTick(function () {
          if (!this.$refs.editImage) {
            return;
          }
          let cropper = new Cropper(this.$refs.editImage, {
            aspectRatio: this.aspectWidth / this.aspectHeight,
            viewMode: 1,
            maxHeight: 210,
          });
          this.cropper = cropper;
        });
      }
    },
  },*/
  methods: {
    addOffer(cropper) {
      let oldFile = this.file;
      if (cropper) {
        let binStr = atob(this.cropper.getCroppedCanvas().toDataURL(oldFile.type).split(',')[1]);
        let arr = new Uint8Array(binStr.length);
        for (let i = 0; i < binStr.length; i++) {
          arr[i] = binStr.charCodeAt(i);
        }
        this.file = new File([arr], oldFile.name, { type: oldFile.type });
      }
      let file = this.file;

      if (file.size >= this.fileSize) {
        const mb = 1/1000000;
        this.setAlert(`Too large file (${(file.size*mb).toFixed(1)}Mb)`
          + `, maximum is (${this.fileSize*mb}Mb), try cropping it more, or use another file`,
          6, 'danger');
      } else {
        const data = new FormData();
        data.append('file', file);
        // file.uploadProgress = 0;
        // file.transcodeProgress = 0;
        // file.uploading = true;
        // file.state = 'UPLOADING';

        const config = {
          headers: {
            size: file.size,
            'Content-Type': 'multipart/form-data'
          },
          /* onUploadProgress: (progressEvent) => {
            console.log(progressEvent);
          }, */
        };

        this.uploadStatus = 'UPLOADING';

        const payload = { data, config };
        payload.entityId = this.entityId;
        payload.entityType = this.finalEntityType;
        payload.mediaType = this.mediaType;
        this.$store.dispatch(`${this.namespace}add${this.role}${this.vuexEntityType}Media`, payload).then((response) => {
          console.log('uploader response', response);
          this.resetEdit();
          if (!this.role.includes('User')) {
            this.setAlert(`Successfully changed ${this.entity} ${this.mediaType}!`, 3, 'success');
          }
          this.$emit('pickedFile', response);
        }).catch(() => {
          this.uploadStatus = 'FAILED';
        });
      }
    },
    inputFilter(file) {
      if (file) {
        if (/\.(gif|jpg|jpeg|png|webp)$/i.test(file.name)) {
          this.file = file;
          let URL = window.URL || window.webkitURL;
          if (URL && URL.createObjectURL) {
            this.file.url = URL.createObjectURL(file);
          }

          if (isNaN(this.aspectWidth) && isNaN(this.aspectHeight)) {
            this.addOffer(false);
          } else {
            this.edit = true;
            this.$nextTick(function () {
              if (!this.$refs.editImage) {
                return;
              }
              let cropper = new Cropper(this.$refs.editImage, {
                aspectRatio: this.aspectWidth / this.aspectHeight,
                viewMode: 1,
                maxHeight: 210,
              });
              this.cropper = cropper;
            });
          }
        } else if (/\.(webm|mp4|m4v|mov)$/i.test(file.name)
          || (this.fileType === 'pdf' && /\.(pdf)$/i.test(file.name))) {
          this.file = file;
          let URL = window.URL || window.webkitURL;
          if (URL && URL.createObjectURL) {
            this.file.url = URL.createObjectURL(file);
          }
          this.addOffer(false);
        } else {
          this.setAlert('Please choose correct media', 3, 'danger');
          return this.resetEdit();
        }
      }
    },
    resetEdit() {
      if (this.keepFeFile) {
        this.feSource = URL.createObjectURL(this.file);
      }
      this.file = {};
      this.uploadStatus = 'READY';
      this.dragOver = false;
      this.edit = false;
      if (this.cropper) {
        this.cropper.destroy();
        this.cropper = false;
      }
    },
    gotoPage(index) {
      const pageNr = this.pageNr + index;
      if (pageNr <= this.pageCount && pageNr >= 1) {
        this.pageNr = pageNr;
      }
    },
    alert(message, seconds) {
      EventBus.$emit('setAlert', { message, close: 'OK', seconds, top: 0, bot: 'unset', left: 0, right: 0  });
    },
    checkMediaType() {
      if (this.mediaType === 'Pdf') {
        return 'uploader-pdf'
      } else {
        return 'uploader-picture'
      }
    },
    resizeCanvas() {
      const canvas = document.getElementsByTagName("CANVAS");
      if (canvas && canvas.length) {
        canvas[0].style.width = "auto";
        canvas[0].style.maxHeight = "250px";
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import "./Uploader.scss";
</style>

<style lang="scss">
.cropper-container {
  width: 100%;
  .cropper-crop{
    border-radius: 8px 8px 0 0;
  }
}
</style>
