import axios from 'axios';

export default {
  // Admin
  findAdminGames: (payload) => {
    return axios.put(`/api/admin/games/${payload.entityType}`, payload)
      .then(response => ({
        games: response.data.games != null
          ? response.data.games : [],
      }));
  },

  getAdminGame: (payload) => {
    return axios.get(`/api/admin/games/${payload.entityType}/${payload.entityId}`)
      .then(response => ({
        game: response.data.game != null
          ? response.data.game : [],
      }));
  },

  addAdminGame: (payload) => {
    return axios.post(`/api/admin/games/${payload.entityType}`, payload)
      .then(response => ({
        game: response.data.game != null
          ? response.data.game : {}
      }));
  },

  saveAdminGame: (payload) => {
    return axios.put(`/api/admin/games/${payload.entityType}/${payload.entityId}`, payload)
      .then(response => ({
        game: response.data.game != null
          ? response.data.game : {}
      }));
  },

  publiciseAdminGame: (payload) => {
    return axios.put(`/api/admin/games/${payload.entityType}/${payload.entityId}/publicise`, payload)
      .then(response => ({
        game: response.data.game != null
          ? response.data.game : {},
      }));
  },

  addAdminGameMedia: (payload) => {
    return axios.put(`/api/admin/games/${payload.entityType}/${payload.entityId}/media/${payload.mediaType}`, payload.data, payload.config)
      .then(response => ({
        game: response.data.game != null
          ? response.data.game : {},
      }));
  },

  deleteAdminGame: (payload) => {
    return axios.delete(`/api/admin/games/${payload.entityType}/${payload.entityId}`)
      .then(response => ({
        game: response.data.game != null
          ? response.data.game : {},
      }));
  },

  sendAdminGameReminder: (payload) => {
    return axios.put(`/api/admin/games/${payload.entityType}/${payload.entityId}/reminder`)
      .then(response => response);
  },

  sendAdminGameThanks: (payload) => {
    return axios.put(`/api/admin/games/${payload.entityType}/${payload.entityId}/thanks`)
      .then(response => response);
  },

  getAdminGameParticipants: (payload) => {
    return axios.get(`/api/admin/games/${payload.entityType}/${payload.entityId}/participants`)
      .then(response => response);
  },

  deleteAdminGameParticipants: (payload) => {
    return axios.delete(`/api/admin/games/${payload.entityType}/${payload.entityId}/participants`)
      .then(response => response);
  },

  // Consumer
  getNarGame: (payload) => {
    return axios.get(`/api/nar/games/${payload.entityType}/${payload.entityId}`)
      .then(response => ({
        game: response.data.game != null
          ? response.data.game : {},
        participation: response.data.participation != null
          ? response.data.participation : {},
        canAddParticipation: response.data.canAddParticipation != null
          ? response.data.canAddParticipation : false,
        reason: response.data.reason != null
          ? response.data.reason : '',
      }));
  },

  getGame: (payload) => {
    return axios.get(`/api/games/${payload.entityType}/${payload.entityId}`)
      .then(response => ({
        game: response.data.game != null
          ? response.data.game : {},
        participation: response.data.participation != null
          ? response.data.participation : {},
        canAddParticipation: response.data.canAddParticipation != null
          ? response.data.canAddParticipation : false,
        reason: response.data.reason != null
          ? response.data.reason : '',
      }));
  },

  participate: (payload) => {
    return axios.post(`/api/games/${payload.entityType}/${payload.entityId}/participate`, payload)
      .then(response => ({
        participation: response.data.participation != null
          ? response.data.participation : {},
        user: response.data.user != null
          ? response.data.user : {},
      }));
  },

  addGamePoint: (payload) => {
    return axios.post(`/api/games/${payload.entityType}/${payload.entityId}/point`, payload)
      .then(response => ({
        participation: response.data.participation != null
          ? response.data.participation : {},
      }));
  },

  answerGameSurvey: (payload) => {
    return axios.put(`/api/games/${payload.entityType}/${payload.entityId}/survey`, payload)
      .then(response => ({
        participation: response.data.participation != null
          ? response.data.participation : {},
      }))
  }
};
