import axios from 'axios';

export default {
  // Admin
  findAdminPVRetailer: (data) => {
    return axios.put(`/api/pv/admin/retailers`, data)
      .then(response => {
        return {
          retailers: response.data.retailers != null
            ? response.data.retailers : [],
      }});
  },

  addAdminPVRetailer: (payload) => {
    return axios.post('/api/pv/admin/retailers', payload)
      .then(response => ({
        retailer: response.data.retailer != null
          ? response.data.retailer : {},
      }));
  },

  saveAdminPVRetailer: (payload) => {
    return axios.put(`/api/pv/admin/retailers/${payload.entityId}`, payload)
      .then(response => ({
        retailer: response.data.retailer != null
          ? response.data.retailer : {},
      }));
  },

  getAdminPVRetailer: (payload) => {
    return axios.get(`/api/pv/admin/retailers/${payload.entityId}`, payload)
      .then(response => ({
        retailer: response.data.retailer != null
          ? response.data.retailer : {},
      }));
  },

  deleteAdminPVRetailer: (payload) => {
    return axios.delete(`/api/pv/admin/retailers/${payload.entityId}?deleteCode=${payload.deleteCode}`)
      .then(response => ({
        message: response.data.message != null
          ? response.data.message : '',
      }));
  },

  // Company
  findCompanyRetailers: (data) => {
    return axios.put('/api/pv/c/retailers', data)
      .then(response => {
        return { retailers: response.data.retailers != null
          ? response.data.retailers : []}
      });
  },

  getCompanyRetailer: (payload) => {
    return axios.get(`/api/pv/c/retailers/${payload.retailerId}`)
      .then(response => ({
        retailer: response.data.retailer != null
          ? response.data.retailer : {},
      }));
  },

  addCompanyRetailer: (payload) => {
    return axios.post('/api/pv/c/retailers', payload)
      .then(response => ({
        retailer: response.data.retailer != null
          ? response.data.retailer : {},
      }));
  },

  saveCompanyRetailer: (payload) => {
    return axios.put(`/api/pv/c/retailers/${payload.entityId}`, payload)
      .then(response => ({
        retailer: response.data.retailer != null
          ? response.data.retailer : {},
      }));
  },

  deleteCompanyRetailer: (entityId) => {
    return axios.delete(`/api/pv/c/retailers/${entityId}`)
      .then(response => ({
        message: response.data.message != null
          ? response.data.message : '',
      }));
  },
};
