/* eslint-disable no-shadow, no-param-reassign */
import blockApi from '../../api/pvBlock.api';

// initial state
const state = {
  blockQuery: '',

  blocked: [],
  blockStatus: '',
};

// getters
const getters = {
  blockQuery: state => state.blockQuery,

  blocked: state => state.blocked,
  blockStatus: state => state.blockStatus,
};

// actions
const actions = {
  // ADMIN
  findAdminBlocked({ commit }, payload) {
    commit('BLOCK_STATUS', 'LOADING');
    return blockApi.findAdminBlocked(payload)
      .then((response) => {
        commit('BLOCKED', response.blocked);
        commit('BLOCK_STATUS', 'FINISHED');
      });
  },

  addAdminBlock({ commit }, payload) {
    commit('BLOCK_STATUS', 'LOADING');
    return blockApi.addAdminBlock(payload)
      .then((response) => {
        commit('ADD_BLOCK', response.block);
        commit('BLOCK_STATUS', 'FINISHED');
        return;
      });
  },

  deleteAdminBlock({ commit }, payload) {
    commit('BLOCK_STATUS', 'LOADING');
    return blockApi.deleteAdminBlock(payload.blockId)
      .then(() => {
        commit('DELETE_BLOCK', payload.blockId);
        commit('BLOCK_STATUS', 'FINISHED');
        return;
      });
  },
};

// mutations
const mutations = {
  BLOCK_STATUS(state, status) {
    state.blockStatus = status;
  },
  BLOCK_QUERY(state, blockQuery) {
    state.blockQuery = blockQuery;
  },
  BLOCKED(state, blocked) {
    state.blocked = blocked;
  },
  ADD_BLOCK(state, block) {
    state.blocked.unshift(block);
  },
  DELETE_BLOCK(state, blockId) {
    for (let i = 0; i < state.blocked.length; i += 1) {
      if (state.blocked[i]._id === blockId) {
        state.blocked.splice(i, 1);
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
