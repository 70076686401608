<template>
  <div
    v-if="loadedPage"
    :style="cssProps">
    <div class="game-desktop-background"></div>
    <span
      v-if="isDevelopment"
      class="dev-navigation">
      DEV
      <router-link class="btn" :to="`${windowOrigin}/g/qr-hunt/${gameId}`">home</router-link>
    </span>

    <div class="game-page">
      <!--<div
        class="btn btn-activate"
        :style="`color: ${game.accent_color}`"
        @click="$router.push({ name: 'Home' })">
        Home
      </div>-->

      <div
        v-if="step == 'onboarding'"
        class="onboarding-container">
        <div class="image-container">
          <div
            v-if="game.terms_text"
            class="info-icon-bottom-right"
            @click="tocModal = true"
            style="cursor: pointer;">
            <iconComponent
              class="map-icon-symbol"
              symbol="info"
              width="18px"/>
          </div>
          <img v-if="game.landing_image" :src="game.landing_image.url" alt="" />
          <div
            v-if="game.map_image"
            class="map-icon-right"
            @click="mapModal = true"
            style="cursor: pointer;">
            <iconComponent
              class="map-icon-symbol"
              symbol="map"
              width="18px"/>
          </div>
        </div>
        <div class="content-container">
          <div
            class="btn-medium btn-absolute"
            @click="startGame()">
            {{ game.gohunt_button_text }}
          </div>
          <div v-html="game.landing_text"></div>

          <!-- <div
            v-if="game.map_image"
            class="btn-medium btn-absolute"
            :style="`background: ${game.accent_color}`"
            @click="mapModal = true">
            {{ game.map_button_text }}
          </div> -->
        </div>
        <!--<h5>Hur funkar Philips-jakten?</h5>
        <p>I Idre Fjäll sitter 10 stycken QR-koder utplacerade på olika platser. Jakten går ut på att du ska skanna alla 10 koder för att få vara med i utlottningen av fina Philips-produkter.</p>
        <p>Så ut på fjället och börja jaga!</p>-->

      </div>
      <div
        v-else
        class="game-page-absolute">
        <div v-html="game.gohunt_text"></div>
        <div
          class="btn"
          @click="gotoHome()">
          {{ game.gohunt_button_text }}
        </div>
      </div>

      <!--<div class="onboarding-footer">
        <div class="onboarding-footer__contacts">
          <h5>Kontaktuppgifter</h5>
          <p>info@philipsjakten.se</p>
        </div>
      </div>-->
    </div>

    <modal
      v-if="mapModal"
      @close="mapModal = false"
      :header="{ closeButton: true }"
      modalType="fullscreen" size="xlarge">
      <slot>
        <div class="game-map"
          @click="enlargedMap = !enlargedMap">
          <img :src="game.map_image.url" alt="" class="game-map-image"
          :class="{
            'game-map-image-enlarged': enlargedMap,
          }">
        </div>

      </slot>
    </modal>

    <modal
      v-if="tocModal"
      @close="tocModal = false"
      size="medium" padding="0" :terms="true">
      <slot>
        <div class="terms-wrapper">
          <div v-html="game.terms_text" class="html-text"></div>
        </div>
        <div class="terms-buttons">
          <div
            v-if="authenticated && game.terms_merchandise && !participation.accepted_merchandise && !hasAcceptedGame"
            class="terms-merchandise-grid">
            <inputField
              v-model="acceptMerchandise"
              inputType="checkbox"/>
            <span
              class="terms-merchandise-text"
              v-html="game.terms_merchandise"></span>
          </div>

          <div v-if="authenticated && !hasAcceptedGame" class="btn btn-activate" @click="acceptGameTerms()">
            {{ transl.UserPV.accept }}
          </div>

          <div class="btn btn-secondary" @click="tocModal = false">
            {{ transl.UserPV.close }}
          </div>
        </div>
      </slot>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BeforeEnterMixin from '../../mixins/BeforeEnterMixin';
import EntityMixin from '../../mixins/EntityMixin';
import InputField from '../../stories/components/InputField/InputField';

export default {
  name: 'QrHuntOnboarding',
  components: {
    InputField,
  },
  props: ['gameId'],
  mixins: [BeforeEnterMixin, EntityMixin],
  data() {
    return {
      gameType: 'QrHunt',
      isDevelopment: process.env.NODE_ENV !== 'production',
      mapModal: false,
      tocModal: false,
      acceptTerms: false,
      acceptMerchandise: true,
      step: 'onboarding',
      loadedPage: false,
      enlargedMap: false,
    };
  },
  mounted() {
    this.getGame();
  },
  watch: {
    authenticated(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getGame();
      }
    },
  },
  computed: {
    ...mapGetters([
      'authenticated',
      'user',
      'participation',
      'canAddParticipation',
      'reason',
    ]),
    cssProps() {
      return {
        '--game-desktop-bg-color': this.game.desktop_bg_color,
        '--game-bg-color': this.game.bg_color,
        '--game-accent-color': this.game.accent_color,
        '--game-text-color': this.game.text_color,
      }
    },
    hasCurrentParticipation() {
      return this.participation && this.participation._id;
    },
    hasAcceptedGame() {
      if (!this.user || !this.user._id) {
        return false;
      }
      for (let i = 0; i < this.user.accepted_games.length; i++) {
        const acceptedGame = this.user.accepted_games[i];
        if (acceptedGame._id === this.gameId
          || acceptedGame.unique_name === this.gameId) {
          return true;
        }
      }
      return false;
    },
  },
  methods: {
    getGame() {
      const el = this;
      this.$store.dispatch('getGame', {
        authenticated: this.authenticated,
        entityType: this.gameType,
        entityId: this.gameId,
      }).then((success) => {
        if (!success) {
          if (!el.game || !el.game._id) {
            el.setAlert(el.reason);
            el.$router.push({ name: 'UserStart' });
            return;
          }
          el.gotoError();
          return;
        }

        el.pushDataLayer({
          parentCompany: el.game.company.parentCompany,
          company: el.game.company,
          game: el.game,
          action: 'Enter',
        });

        el.setPageTitle(el.game.name, el.game.company.name);
        el.loadedPage = true;

        /*if (el.authenticated && !el.canAddParticipation && !el.hasCurrentParticipation) {
          el.gotoError();
        } else*/
        if (el.authenticated && !el.hasCurrentParticipation && !el.game.tiebreak && !el.hasAcceptedGame) {
          el.tocModal = true;
        }
      });
    },
    startGame() {
      if (!this.authenticated && this.game.must_login) {
        this.showLogin();
      } else if (this.game.terms_must_accept
        && this.authenticated && !this.game.tiebreak
        && !this.hasCurrentParticipation
        && !this.acceptTerms
        && !this.hasAcceptedGame) {
        this.tocModal = true;
      } else if (this.authenticated && !this.game.tiebreak
        && !this.hasCurrentParticipation) {
        this.participate();
      } else {
        this.gotoHome();
      }
    },
    acceptGameTerms() {
      this.tocModal = false;
      this.acceptTerms = true;
    },
    participate() {
      const el = this;
      this.$store.dispatch('participate', this.attach({
        acceptTerms: this.acceptTerms || this.hasAcceptedGame,
        acceptMerchandise: this.acceptMerchandise,
        thirdPartyCrmName: this.thirdPartyCrmName,
      }))
      .then((success) => {
        if (!success) {
          el.gotoError();
          // el.setAlert(el.reason);
          return;
        }

        if (el.game.gohunt_text) {
          el.step = 'gohunt_text';
        } else {
          el.gotoHome();
        }
      });
    },
    gotoHome() {
      console.log('Onboaring gotoHome');
      const query = this.$route.query;
      if (Object.keys(query).length) {
        this.$router.replace({ name: 'QrHuntOnboarding' });
      }
      this.$router.push({ name: 'QrHuntHome', query });
    },
    gotoError() {
      console.log('Onboaring gotoError');
      this.$router.push({ name: 'QrHuntError' });
    },
    showLogin() {
      this.$emit('setLoginView', {});
    },
    attach(obj) {
      obj.entityId = this.gameId;
      obj.entityType = this.gameType;
      return obj;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/games/QrHunt/Onboarding";
</style>
