<template>
  <div
    v-if="loadedPage"
    class="admin-content--container">
    <div class="admin-archived-draft-header">
      You have
      <span>{{ goodiebags.length + offers.length }} Collections and Activations</span>
      in your drafts
    </div>
    <div>Drafts are Activations or Collections in progress that are not yet published.</div>

    <div class="admin-archived-draft-header">
      Collections in draft
    </div>
    <aList
      entityType="Collections"
      columnsWidth="400"
      :entities="goodiebags"
      :keys="goodiebagKeys"
      @click="$router.push({ name: 'CompanyGoodiebag', params: { goodiebagId: goodiebags[$event.index]._id } })"/>

    <div class="admin-archived-draft-header">
      Activations in draft
    </div>
    <aList
      entityType="Activations"
      columnsWidth="400"
      :entities="offers"
      :keys="offerKeys"
      @click="$router.push({ name: 'CompanyOffer', params: { offerId: offers[$event.index]._id } })"/>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import aList from "../components/AList";
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';

export default {
  name: "CompanyDrafts",
  components: {
    aList,
  },
  mixins: [BeforeEnterMixin],
  mounted() {
    this.findCompanyOffers('');
    this.findCompanyGoodiebags('');
  },
  computed: {
    ...mapGetters('search', {
      'offers': 'offers',
      'goodiebags': 'goodiebags',
    }),
  },
  data() {
    return {
      loadedPage: false,
      goodiebagId: '',
      search: '',
      showGoodiebagDialog: false,
      showPreviewGoodiebagModal: false,
      showPreviewCompanyModal: false,
      previewWidth: 'computer',
      goodiebagKeys: [
        {
          title: 'Name',
          key: 'name'
        },
        {
          title: 'info',
          key: 'info'
        },
        {
          title: 'Last updated',
          key: 'updated_at',
          unix: 'MMM DD, YYYY'
        }
      ],
      offerKeys: [
        {
          title: 'Header',
          key: 'first_header'
        },
        {
          title: 'Category',
          key: 'goodiebag.name'
        },
        {
          title: 'Last updated',
          key: 'updated_at',
          unix: 'MMM DD, YYYY',
        }
      ],
    };
  },
  destroyed() {
    this.setOffers([]);
    this.setGoodiebags([]);
  },
  methods: {
    ...mapMutations('search', {
      setOffers: 'OFFERS',
      setGoodiebags: 'GOODIEBAGS',
    }),
    findCompanyOffers(query) {
      this.$store.dispatch('search/findCompanyOffers', { query, public: false }).then(() => {
        this.loadedPage = true;
      });
    },
    findCompanyGoodiebags(query) {
      this.$store.dispatch('search/findCompanyGoodiebags', { query, parentGoodiebagId: 'all', public: false }).then(() => {
        this.loadedPage = true;
      });
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/scss/pages/CompanyDrafts";
</style>
