<template>
  <div>
    <div><h1>Activation Manager</h1></div>
    <div class="pv-picker-wrapper">
      <div class="pv-container" @click="$router.push({ name: 'AdminPVCompanies' })">
        <h2>Companies</h2>
        <iconComponent symbol="home" width="100px"/>
      </div>
      <div class="pv-container" @click="$router.push({ name: 'AdminPVCampaigns' })">
        <h2>Campaigns</h2>
        <iconComponent symbol="heart" width="100px"/>
      </div>
      <div class="pv-container" @click="$router.push({ name: 'AdminPVActivations' })">
        <h2>Activations</h2>
        <iconComponent symbol="rocket" width="100px"/>
      </div>
      <div class="pv-container" @click="$router.push({ name: 'AdminPVReceipts' })">
        <h2>Receipts</h2>
        <iconComponent symbol="paperRoll" width="100px"/>
      </div>
      <div class="pv-container" @click="$router.push({ name: 'AdminPVChains' })">
        <h2>Chains</h2>
        <iconComponent symbol="home" width="100px"/>
      </div>
      <div class="pv-container" @click="$router.push({ name: 'AdminPVRetailers' })">
        <h2>Retailers</h2>
        <iconComponent symbol="home" width="100px"/>
      </div>
      <div class="pv-container" @click="$router.push({ name: 'AdminPVStores' })">
        <h2>Stores</h2>
        <iconComponent symbol="home" width="100px"/>
      </div>
      <div class="pv-container" @click="$router.push({ name: 'AdminPVFlagged' })">
        <h2>Flagged</h2>
        <iconComponent symbol="stop" width="100px"/>
      </div>
      <div class="pv-container" @click="$router.push({ name: 'AdminPVBlocked' })">
        <h2>Blocked</h2>
        <iconComponent symbol="mobileCrossed" width="100px"/>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "AdminPVHome",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '../assets/scss/pages/AdminPVHome';
</style>
