import axios from 'axios';

export default {
  // Admin
  findAdminCompanies: (data) => {
    return axios.put('/api/admin/companies', data)
      .then(response => ({
        companies: response.data.companies != null
          ? response.data.companies : [],
      }));
  },

  addAdminCompany: (data) => {
    return axios.post('/api/admin/companies', data)
      .then(response => ({
        company: response.data.company != null
          ? response.data.company : {},
      }));
  },

  getAdminCompany: (data) => {
    return axios.get(`/api/admin/companies/${data.entityId}`)
      .then(response => ({
        company: response.data.company != null
          ? response.data.company : {},
      }));
  },

  saveAdminCompany: (payload) => {
    return axios.put(`/api/admin/companies/${payload.entityId}`, payload)
      .then(response => ({
        company: response.data.company != null
          ? response.data.company : {},
      }));
  },

  publiciseAdminCompany: (payload) => {
    return axios.put(`/api/admin/companies/${payload.entityId}/publicise`, payload)
      .then(response => ({
        company: response.data.company != null
          ? response.data.company : {},
      }));
  },

  addAdminCompanyMedia: (payload) => {
    return axios.put(`/api/admin/companies/${payload.entityId}/media/${payload.mediaType}`, payload.data, payload.config)
      .then(response => ({
        company: response.data.company != null
          ? response.data.company : {},
      }));
  },

  addAdminCompanyArbitraryMedia: (payload) => {
    return axios.put(`/api/admin/companies/${payload.entityId}/media`, payload.data, payload.config)
      .then(response => ({
        mediaRef: response.data.mediaRef != null
          ? response.data.mediaRef : {},
      }));
  },

  finishAdminCompanyLinks: (payload) => {
    return axios.put(`/api/admin/companies/${payload.entityId}/links-done`)
      .then(response => ({
        company: response.data.company != null
          ? response.data.company : [],
      }));
  },

  deleteAdminCompany: (companyId) => {
    return axios.delete(`/api/admin/companies/${companyId}`)
      .then(response => ({
        message: response.data.message != null
          ? response.data.message : '',
      }));
  },

  // Consumer
  findUserCompanies: (query, number) => {
    const data = {
      query,
      number,
    }
    return axios.put('/api/companies', data)
      .then(response => ({
        companies: response.data.companies != null
          ? response.data.companies : [],
      }));
  },

  getCompany: (data) => {
    return axios.get(`/api/companies/${data.companyId}`)
      .then(response => ({
        company: response.data.company != null
          ? response.data.company : {},
      }));
  },

  // Session Required
  getUserCompanies: () => {
    return axios.get(`/api/users/companies`).then(response => ({
      companies: response.data.companies != null
        ? response.data.companies : {},
    }));
  },

  pickCompany: (data) => {
    return axios.put('/api/users/companies/pick', data).then(response => ({
      company: response.data.company != null
        ? response.data.company : {},
    }));
  },

  linkCompany: (data) => {
    return axios.put('/api/nar/companies/link', { code: data.code }).then(response => ({
      company: response.data.company != null
        ? response.data.company : {},
    }));
  },

  // NAR Company
  getNarPickedCompany: () => {
    return axios.get('/api/users/companies/picked').then(response => {
      return response.data.success;
    });
  },

  // Company
  getPickedCompany: () => {
    return axios.get('/api/c/companies').then(response => ({
      company: response.data.company != null
        ? response.data.company : {},
    }));
  },
};
