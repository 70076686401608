<template>
  <div
    v-if="loadedPage"
    class="admin-content--container">
    <EntityEditor
      v-if="campaign && campaign._id"
      :title= "`Change campaign '${ campaign.name }' (${ campaign.name })`"
      :admin="true"
      :entity="campaign"
      entity-type="PVCampaign"/>

    <div v-if="campaign && campaign._id && rates">
      <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; padding: 10px; background: white;">
        <div>Total <b>{{ formatNumber(campaign.cashback_total_amount) }} {{ campaign.cashback_currency }}</b></div>
        <div>Estimated Cashbacks (everyone who got the callback) <b>{{ formatNumber(cashbackSum) }} {{ campaign.cashback_currency }}</b></div>
        <div>Estimated Balance <b>{{ formatNumber(campaign.cashback_total_amount - cashbackSum) }} {{ campaign.cashback_currency }}</b></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';
import EntityEditor from '../stories/components/EntityEditor/EntityEditor.vue';

export default {
  name: "AdminPVCampaign",
  components: {
    EntityEditor,
  },
  mixins: [BeforeEnterMixin],
  props: ['campaignId'],
  mounted() {
    this.$store.dispatch('pvCampaigns/getAdminPVCampaign', { entityId: this.campaignId }).then(() => {
      this.$store.dispatch('pvCampaigns/getAdminPVExangerates', { from: this.campaign?.cashback_currency || 'SEK' }).then((rates) => {
        this.rates = rates;
        this.loadedPage = true;
      });
    });
  },
  computed: {
    ...mapGetters('pvCampaigns', ['campaign']),
    cashbackSum() {
      let result = 0;
      for (let i = 0; i < this.campaign?.cashbacks?.length; i++) {
        const cashback = this.campaign.cashbacks[i];
        result += cashback.amount * this.rates[cashback.currency];
      }
      return result;
    }
  },
  data() {
    return {
      loadedPage: false,
      rates: {},
    };
  },
  methods: {
    // Set space for every thousand
    formatNumber(value) {
      if (typeof value !== 'number') {
        return 0;
      }
      return value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.spinner-overlay {
  position: absolute;
  top: 25%;
  width: 97%;
  margin: auto;
  z-index: 1000;
}
.adver-card {
  border: 1px solid grey;
}
.the-list {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-gap: 1px;
  .a-list-item {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border: 1px solid grey;
    height: 100px;
    .company-item-image{
      width: fit-content;
      height: fit-content;
    }
    .company-item-title{
      color: white;
      text-align: center;
      position: absolute;
      top: 0;
    }
  }
}
.company-preview{
  display: grid;
  // box-shadow: -1px 0px 2px grey;
  min-height: calc(100vh - 48px);
  .close-preview{
    cursor: pointer;
    width: fit-content;
    position: absolute;
    top: 2px;
    right: 5px;
  }
}
.add-company-button{
  z-index: 100;
  position: absolute;
  bottom: 5rem;
  right: 5rem;
}
.close-preview-button{
  position: absolute;
  top: 5rem;
  right: 1rem;
}
</style>
