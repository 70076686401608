/* eslint-disable no-shadow, no-param-reassign */
import Vue from 'vue';
import gameApi from '../../api/game.api';

// initial state
const state = {
  game: {},
  games: [],
  gameStatus: '',
};

// getters
const getters = {
  game: state => state.game,
  games: state => state.games,
  gameStatus: state => state.gameStatus,
};

// actions
const actions = {
  // payload = { type: 'QrHunt' }
  // Admin
  findAdminGames({ commit }, payload) {
    commit('GAME_STATUS', 'LOADING');
    return gameApi.findAdminGames(payload)
      .then((response) => {
        commit('GAMES', response.games);
        commit('GAME_STATUS', 'FINISHED');
      });
  },

  getAdminGame({ commit }, payload) {
    commit('GAME_STATUS', 'LOADING');
    return gameApi.getAdminGame(payload)
      .then((response) => {
        commit('GAME', response.game);
        commit('GAME_STATUS', 'FINISHED');
      });
  },

  addAdminGame({ commit }, payload) {
    commit('GAME_STATUS', 'LOADING');
    return gameApi.addAdminGame(payload)
      .then((response) => {
        commit('ADD_GAME', response.game);
        commit('GAME_STATUS', 'FINISHED');
      });
  },

  addAdminGameMedia({ commit }, payload) {
    commit('GAME_STATUS', 'LOADING');
    return gameApi.addAdminGameMedia(payload)
      .then((response) => {
        commit('GAME', response.game);
        commit('GAME_STATUS', 'FINISHED');
      });
  },

  saveAdminGame({ commit }, payload) {
    commit('GAME_STATUS', 'LOADING');
    return gameApi.saveAdminGame(payload)
      .then((response) => {
        commit('GAME', response.game);
        commit('GAME_STATUS', 'FINISHED');
      });
  },

  publiciseAdminGame({ commit }, payload) {
    commit('GAME_STATUS', 'LOADING');
    return gameApi.publiciseAdminGame(payload)
      .then((response) => {
        commit('GAME', response.game);
        commit('GAME_STATUS', 'FINISHED');
      });
  },

  deleteAdminGame({ commit }, payload) {
    commit('GOODIEBAG_STATUS', 'LOADING');
    return gameApi.deleteAdminGame(payload)
      .then(() => {
        commit('GAME_STATUS', 'FINISHED');
        return;
      });
  },

  // Company
  // ...
};

// mutations
const mutations = {
  GAME(state, game) {
    state.game = game;
  },
  GAMES(state, games) {
    state.games = games;
  },
  ADD_GAME(state, game) {
    state.games.unshift(game);
  },
  SAVE_GAME(state, game) {
    for (let i = 0; i < state.games.length; i += 1) {
      if (state.games[i]._id === game._id) {
        Vue.set(state.games, i, game);
      }
    }
  },
  GAME_STATUS(state, status) {
    state.gameStatus = status;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
