<template>
  <div class="--email-template">
    <div class="--templates">
      <div class="--none-selected" v-if="!isActive">
        <p>Please select email template</p>
      </div>

      <div class="--device-selector" v-if="isActive">
        <div class="-phone"  @click="deviceSelected = 'mobile'">
            <iconComponent symbol="smartphone" :fill="deviceSelected === 'mobile' ? 'black' : 'gray'" :stroke="deviceSelected === 'mobile' ? 'black' : 'gray'"/>
        </div>

        <div class="--tablet"  @click="deviceSelected = 'tablet-device'">
          <iconComponent symbol="tablet" :fill="deviceSelected === 'tablet-device' ? 'black' : 'gray'" :stroke="deviceSelected === 'tablet' ? 'black' : 'gray'"/>
        </div>

        <div class="-desktop"  @click="deviceSelected = 'desktop'">
          <iconComponent symbol="computer" :fill="deviceSelected === 'computer' ? 'black' : 'gray'" :stroke="deviceSelected === 'computer' ? 'black' : 'gray'"/>
        </div>
      </div>

      <div v-html="winnerEmail" class="--verification" :class="deviceSelected" v-if="emailType.winnerEmail.active"></div>
      <!--<div v-html="verificationEmail" class="--verification" :class="deviceSelected" v-if="emailType.verificationSignUp.active || emailType.verificationChangePhone.active || emailType.verificationDefault.active"></div>-->
      <!--<div v-html="cashback" class="--verification" :class="deviceSelected" v-if="emailType.cashback.active"></div>-->
      <!--<div v-html="senseoEmail" class="--verification" :class="deviceSelected" v-if="emailType.senseoEmail.active"></div>
      <div v-html="senseoTourDeFrance" class="--verification" :class="deviceSelected" v-if="emailType.senseoTourDeFrance.active"></div>
      <div v-html="candyKingHalloweenDK22" class="--verification" :class="deviceSelected" v-if="emailType.candyKingHalloweenDK22.active"></div>
      <div v-html="candyKingHalloweenSE22" class="--verification" :class="deviceSelected" v-if="emailType.candyKingHalloweenSE22.active"></div>
      <div v-html="PhilipsAmbilightT100_SE" class="--verification" :class="deviceSelected" v-if="emailType.PhilipsAmbilightT100_SE.active"></div>
      <div v-html="kitchenAidSE22" class="--verification" :class="deviceSelected" v-if="emailType.kitchenAidSE22.active"></div>-->
      <!-- <iframe height="100%" src="Open an html file using a VSCode plugin called Live Server, insert the link that will open in your browser here to test any html document in iframe"></iframe> -->
    </div>


    <div class="--email-selector">
      <div class="--emails" v-for="(email, index) in emailType" :key="index"
           @click="selectEmail(email.name), emailText(email.text)">
        {{ email.name }}
      </div>
      <div class="--renderer">
        <div @click="renderEmail()" class="btn">Render email</div>
      </div>
    </div>

  </div>
</template>

<script>
import rawWinnerEmail from 'raw-loader!./HTML/WinnerEmail.html';
// import rawVerificationEmail from 'raw-loader!./HTML/VerificationEmail.html';
// import rawCashback from 'raw-loader!./HTML/cashback.html';
// import rawSenseoEmail from 'raw-loader!./HTML/SenseoEmail.html';
// import rawSenseoTourDeFrance from 'raw-loader!./HTML/PV/senseo_tdf_planB_22_DK.html';
// import rawcandyKingHalloweenDK22 from 'raw-loader!./HTML/PV/ck_halloween_22_DK.html';
// import rawcandyKingHalloweenSE22 from 'raw-loader!./HTML/PV/ck_halloween_22_SE.html';
// import rawPhilipsAmbilightT100_SE from 'raw-loader!./HTML/PV/philips_ambilight_trial100_SE.html';
// import rawkitchenAidSE22 from 'raw-loader!./HTML/PV/kitchenaid_cashback_22_SE.html';

export default {
  name: 'EmailTemplate',
  components: {},
  data() {
    return {
      deviceSelected: 'mobile',
      emailType: {
        winnerEmail: {
          name: 'Winner E-mail',
          type: 'Winner',
          text: 'winnerMail',
          active: false,
        },
        /*verificationSignUp: {
          name: 'Verification E-mail',
          type: 'Verification',
          text: 'signup',
          active: false,
        },
        verificationChangePhone: {
          name: 'Verification Change Phone E-mail',
          type: 'Verification',
          text: 'changePhone',
          active: false,
        },
        verificationDefault: {
          name: 'Verification Default E-mail',
          type: 'Verification',
          text: 'default',
          active: false,
        },
        cashback: {
          name: 'Cashback',
          type: 'Cashback',
          text: 'Cashback 213',
          active: false,
        },*/
        /*senseoEmail: {
          name: 'Senseo E-mail',
          type: 'Senseo',
          text: 'senseoMail',
          active: false,
        },
        senseoTourDeFrance: {
          name: 'Senseo Tour De France',
          type: 'Senseo',
          text: 'senseoTourDeFrance',
          active: false,
        },
        candyKingHalloweenDK22: {
          name: 'CandyKing Halloween 2022 DK',
          type: 'CandyKing',
          text: 'candyKingHalloweenDK22',
          active: false,
        },
        candyKingHalloweenSE22: {
          name: 'CandyKing Halloween 2022 SE',
          type: 'CandyKing',
          text: 'candyKingHalloweenSE22',
          active: false,
        },
        PhilipsAmbilightT100_SE: {
          name: 'PhilipsAmbilightT100_SE',
          type: 'Philips',
          text: 'PhilipsAmbilightT100_SE',
          active: false,
        },
        kitchenAidSE22: {
          name: 'kitchenAidSE22',
          type: 'KitchenAid',
          text: 'rawkitchenAid_SE_22',
          active: false,
        },*/
      },
      emailBody: {
        subject: '',
        header: '',
        winheader2: '',
        body: '',
        body2: '',
        body3: '',
        body4: '',
        body5: '',
        ul: '',
        ul2: '',
        ol: '',
        ol2: '',
        ol3: '',
        emailFooter: '',
        winbody: '',
        winbody2: '',
        winadress: '',
        emailBtn: '',
        enginioCollection: '',
        bodyH3: '',
        linkButton: '',
        notYou: '',
        copyLink: '',
        verifyExpiry: '',
        cyaBackThere: '',
        btnNoWork: '',
      },
      emailCSS: {
        /**
         * IMPORTANT!!!
         * 
         * RULES:
         * 1. Dont change this format!!
         * 2. No spaces after ":" inside of styling string!
         * 3. No line breaks for one class!
         */
        emailWrapper: 'margin:0;padding:0;word-spacing:normal;background-color:#EA7801;',
        emailWrapperInner: 'text-size-adjust:100%;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;background-color:#EA7801;',
        emailWrapperTable: 'width:100%;border:none;border-spacing:0;',
        emailWrapperTableData: 'padding:1rem 10px;',
        emailWrapperTableInner: 'width:95%;max-width:600px;border:none;border-spacing:0;text-align:left;font-family:Arial,sans-serif;font-size:16px;line-height:22px;color:#363636;',

        emailHeader: 'color:white;padding:40px 30px 30px 30px;text-align:center;font-size:24px;font-weight:bold;',
        emailHeaderContainer: 'text-align:center;',
        emailHeaderImg: 'width:100%;height:auto;display:block;border:none;text-decoration:none;color:#363636;margin:10px auto; max-width:200px;',
        emailHeaderInner: 'padding:30px 25px;background-color:#ffffff;border-radius:10px 10px 0 0;text-align:center;',
        emailHeaderInnerFirst: 'padding:20px 0;margin-top:0;margin-bottom:16px;font-size:32px;line-height:32px;font-weight:bold;letter-spacing:-0.02em;text-align:center;',
        emailHeaderInnerSecond: 'margin:0;text-align:center;',
        emailHeaderImgWrapper: 'padding:0 1em;font-size:24px;line-height:28px;font-weight:bold;background-color:#ffffff;',
        emailHeaderImg2: 'width:100%;height:auto;display:block;border:none;text-decoration:none;color:#363636;margin-top:-1em;margin-bottom:-1em;',
        emailHeaderIconWrapper: 'height:100%;padding-bottom:10px;',
        emailHeaderIcon: 'border-radius:50%; height:100%; width:100%;box-shadow:rgba(149, 157, 165, 0.2) 0px 8px 24px; object-fit:contain;',

        emailBody: 'text-align:center;padding:35px 25px 11px 30px;background-color:#ffffff;border-bottom:1px solid #f0f0f5;border-color:rgba(201,201,207,.35);',
        emailBodyAlt: 'padding:35px 25px 11px 30px;background-color:#ffffff;border-bottom:1px solid #f0f0f5;border-color:rgba(201,201,207,.35);',
        emailBodyImageWrapper: 'display:inline-block;width:100%;vertical-align:top;font-family:Arial,sans-serif;font-size:14px;color:#363636;',
        emailBodyImage: 'width:80%;max-width:115px;margin-bottom:20px;',
        emailBodyText: 'display:inline-block;width:100%;vertical-align:top;font-family:Arial,sans-serif;font-size:16px;line-height:22px;color:#363636;',
        emailBodyText2: 'display:inline-block;width:100%;vertical-align:top;padding-bottom:20px;font-family:Arial,sans-serif;font-size:16px;line-height:22px;color:#363636;',
        emailHeaderP: 'font-size:20px; text-align:left; font-style:normal;color:rgba(226, 0, 124, 1);',
        emailBodyP: 'margin-top:0;margin-bottom:12px;',
        emailBodyPAlt: 'color:rgba(234, 120, 1, 1); font-weight:bold;',
        emailBodyPAlt2: 'padding:35px 0 11px 0;text-align:center;',
        emailBodyPAlt3: 'margin-top:50px;margin-bottom:12px;text-align:center;',
        emailBodyH: 'color:rgba(234, 120, 1, 1);font-weight:700;font-size:28px;line-height:32px;text-align:center;',
        emailBodySubH: 'text-align:center;',
        emailBodyPButton: 'background:#EA7801; text-decoration:none; padding:10px 50px; color:#ffffff; border-radius:4px; display:inline-block; mso-padding-alt:0;text-underline-color:#ff3884;',
        emailBodyPButtonFull: 'background:#EA7801; text-decoration:none; padding:10px 0; color:#ffffff; border-radius:4px; display:inline-block; mso-padding-alt:0;text-underline-color:#ff3884;width:100%;text-align:center;',
        emailBodyPButtonEmpty: 'color:#EA7801; text-decoration:none; padding:10px 0; border-radius:4px; display:inline-block; mso-padding-alt:0;text-underline-color:#ff3884;width:100%;text-align:center; border:2px solid;',
        emailBodyPLink: 'overflow-wrap:anywhere;mso-text-raise:10pt;font-weight:bold;margin:auto;',
        emailBodyPLinkEmpty: 'color:rgb(234, 120, 1);',
        emailTablePadding: 'padding:1em;',

        emailFooter: 'padding:30px;text-align:center;font-size:12px;background-color:#404040;color:#cccccc;',
        emailFooter2: 'padding:30px;text-align:center;font-size:14px;background-color:#f2f2f2;color:#cccccc;border-radius:0 0 10px 10px;',
        emailFooterP: 'margin:0 0 8px 0;',
        emailFooterP2: 'margin:0 0 8px 0;color:#666;text-align:center;',
        emailFooterLink: 'decoration:none; color:white;',
        emailFooterLink2: 'text-decoration:none; color:#666;',
        emailFooterLogo: 'color:white;text-align:center;font-size:24px;font-weight:bold;padding:20px 0;',

        emailLoginWrapperTableInner: 'width:95%;max-width:350px;border:none;border-spacing:0;text-align:left;font-family:Arial,sans-serif;font-size:16px;line-height:22px;color:#363636;',
        emailLoginImg: 'display:block; margin:auto; padding-bottom:10px; width:135px;',
        emailLoginHeader: 'padding:0px 65px;letter-spacing:-0.02em;text-align:center;line-height:30px;font-size:24px;font-weight:bold;',
        emailLoginH3: 'padding:10px 0;margin-top:0;margin-bottom:16px;font-size:24px;line-height:32px;font-weight:bold;letter-spacing:-0.02em; text-align:center;',
        emailLoginBody: 'display:inline-block;width:100%;vertical-align:top;padding:20px 0;font-family:Arial, sans-serif;font-size:16px;line-height:22px;color:black;',
        emailLoginButton: 'text-align:center;width:299px;background:#EA7801;padding:10px;border-radius:3px;color:white;font-size:1.2rem;text-decoration:none;border-radius:3px;',
        emailLoginLink: 'color:white !important; font-weight:bold; font-size:16px; line-height:22px; text-align:center;',
        emailSubtitleSmall: 'font-weight:700;font-size:20px;line-height:25px;text-align:center;',
        
        senseoEmailWrapper: 'margin:0;padding:0;word-spacing:normal;background-color:#4A1A18;',
        senseoLogo: 'width:100px; height:100px;',
        senseoBg: 'padding-top:90px;',
        senseoHero: 'width:311px;height:506px;',
        senseoBodyh3: 'font-size:20px;line-height:24px;color:#000000;font-weight:700',
        senseoBodyItem: 'color:#000000;font-size:16px;line-height:140%;font-weight:400;',
        senseoLi: 'margin-bottom:10px;',
        senseoFooter: 'color:#FF6565;letter-spacing:0.002em;line-height:14px;font-size:12px;',

        ckCode: 'text-align:center;color:#EC6906;font-weight:700;font-size:28px;line-height:32px;margin-top:50px;',
      },
    };
  },
  unmounted() {
    this.style.remove();
  },
  computed: {
    isActive() {
      let activeItem = Object.entries(this.emailType).map(item => item[1]).filter(item => item.active === true);
      if (activeItem[0]) {
      if (activeItem[0].active === true) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    winnerEmail() {
      return this.replaceString(rawWinnerEmail);
    },
    /*verificationEmail() {
      return this.replaceString(rawVerificationEmail);
    },
    cashback() {
      return this.replaceString(rawCashback);
    },*/
    /*senseoEmail() {
      return this.replaceString(rawSenseoEmail);
    },
    senseoTourDeFrance() {
      return this.replaceString(rawSenseoTourDeFrance);
    },
    candyKingHalloweenDK22() {
      return this.replaceString(rawcandyKingHalloweenDK22);
    },
    candyKingHalloweenSE22() {
      return this.replaceString(rawcandyKingHalloweenSE22);
    },
    PhilipsAmbilightT100_SE() {
      return this.replaceString(rawPhilipsAmbilightT100_SE)
    },
    kitchenAidSE22() {
      return this.replaceString(rawkitchenAidSE22)
    }*/
  },
  methods: {
    selectEmail(emailName) {
      this.toggleActive(this.emailType, emailName);
      setTimeout(() => {
        this.grabClasses(this.emailCSS);
      }, 100);
    },
    reset() {
      for (let prop = 0; prop < this.emailBody.length; prop++) {
        delete this.emailBody[prop];
      }
    },
    toggleActive(obj, objName) {
      Object.entries(obj).forEach(([, value]) => {
        if (value.name !== objName) {
          value.active = false;
        } else {
          value.active = true;
        }
      });
    },
    grabClasses(className) {
        Object.entries(className).forEach(([key, value]) => {
          let test = document.querySelectorAll("." + key);
          if (test.length) {
            for (let index = 0; index < test.length; index++) {
                test[index].style.cssText = value;
            }
          }
        });
    },
    replaceString(text) {
      let modifiedText = text;
      for (const key in this.emailBody) {
        let myRegex = new RegExp(`(\\$\{transl\\.|\\\${)(\\b${key}\\b)([}$])`, 'i');
        while (modifiedText.search(myRegex) !== -1) {
          modifiedText = modifiedText.replace(myRegex, this.emailBody[key]).replace('${host}', 'http://localhost:8001');
        }
      }
      return modifiedText;
    },
    emailText(type) {
      if (type === 'signup') {
        this.reset();
        this.emailBody.header = this.transl.email.signupfuntojoin.interpolate({
          username: 'Christian',
        });
        this.emailBody.body = this.transl.email.signupfirststep;
        this.emailBody.bodyH3 = this.transl.email.signuponemorestep;
        this.emailBody.linkButton = this.transl.email.verifyyourmailbtn;
        this.emailBody.notYou = this.transl.email.signupignorenoyou;
      } else if (type === 'changePhone') {
        this.reset();
        this.emailBody.header = this.transl.email.phonesoonupdate;
        this.emailBody.body = this.transl.email.phonechangeinfo;
        this.emailBody.bodyH3 = this.transl.email.phonelinknewnr;
        this.emailBody.linkButton = this.transl.email.verifyyourmailbtn;
        this.emailBody.notYou = this.transl.email.phoneignorenoyou;
      } else if (type == 'winnerMail') {
        this.reset();
        this.emailBody.header =  this.transl.email.emailverifynewheader.interpolate({ username: 'Christian' });
        this.emailBody.body =  this.transl.email.emailinordertoverify;
        this.emailBody.bodyH3 =  this.transl.email.emailconfirmemail;
        this.emailBody.linkButton =  this.transl.email.verifyyourmailbtn;
        this.emailBody.notYou =  this.transl.email.emailignorenoyou;
        this.emailBody.btnNoWork = this.transl.email.btnnowork;
        this.emailBody.copyLink = this.transl.email.copylink;
        this.emailBody.verifyExpiry = this.transl.email.verifyexpiry;
        this.emailBody.cyaBackThere = this.transl.email.cyabackthere;
      } else if (type == 'senseoMail') {
        this.reset();
        // this should be hardcoded into these custom html files
        this.emailBody.subject = 'Här kommer ditt certifikat!',
        this.emailBody.header = 'Hey there Senseo lover!';
        this.emailBody.bodyH3 = 'We hope you are enjoying your purchase 😊';
        this.emailBody.body = 'Thank you for your contribution to help us work towards a better planet! At Senseo we understand the importance of preserving our planet and all in it that we hold dear. This is why we pride ourselves on being a low-environmental impact coffee system, because',
        this.emailBody.body2 = 'We understand that our impact is not limited to our coffee machines & products. In order to make a positive impact on our planet, we have partnered up with Graine de Vie (<a href="https://grainedevie.org/en">grainedevie.org/en</a>) in order to plant 100,000 trees in developing countries.'
        this.emailBody.body3 = 'These trees will help with the protection and reforestation of areas in developing countries, while providing a source of income for the local population. For more information, please see senseo.dk/earthday. Your purchase of XX products means you contributed to the planting of X of these trees. We will send you updates on the planting process so you can see '
        this.emailBody.body4 = 'Thank you again for your contribution, and we hope you enjoy the updates as well as our product into the future!',
        this.emailBody.body5 = '// Team Senseo',
        this.emailBody.ul = 'Uses compostable pads* and 100% certified coffee**'
        this.emailBody.ul2 =  'Our appliances are designed based on sustainability pillars according to the Philips EcoDesign process*** – for further details please see senseo.dk/se/no';
        this.emailBody.ol = 'The tree nursery where the trees will spend the first months, to grow big enough to be planted',
        this.emailBody.ol2 = 'The planting process, where trees are placed in the surroundings',
        this.emailBody.ol3 = 'An update on the growing process, to see these trees develop from small saplings to larger trees',
        this.emailBody.emailFooter = '* Rainforest Alliance Certified. See www.ra.org for further details ** *According to [NEN (EN, FE EN) -13432 [text varies depending on national standard] biodegradable and industrially compostable *** Our SENSEO® machines follow the EcoDesign Process, they are designed with a more responsible approach. More information on: <a href="https://www.senseo.com">www.senseo.com</a>"'
      } else if (type == 'default') {
        this.reset();
        this.emailBody.header = this.transl.email.emailverifynewheader.interpolate({
          username: 'Christian',
        });
        this.emailBody.body = this.transl.email.emailinordertoverify;
        this.emailBody.bodyH3 = this.transl.email.emailconfirmemail;
        this.emailBody.linkButton = this.transl.email.verifyyourmailbtn;
        this.emailBody.notYou = this.transl.email.emailignorenoyou;
      }
    },
    renderEmail() {
        Object.entries(this.emailType).forEach(([, value]) => {
          if (value.active) {
            // In the future we will send the edited HTML file 
            // to the backend to create a local html file we can use as our e-mails
          }
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.--email-template {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;   

  .--templates {
    display: flex;
    justify-content: center;
    background: #F2F2F2;
    border-radius: 10px;
    padding: 10px 100px 50px;
    height: fit-content;
    flex-direction: column;
    .--device-selector {
      padding: 20px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .mobile {
      width: 375px;
    }

    .tablet-device {
      width: 820px;
    }

    .desktop {
      width: 1080px;
    }
  }

  .--email-selector {
    height: 200px;
    width: 260px;
    margin-left: 20px;

    > div {
      padding: 16px 0px 16px 24px;
      cursor: pointer;
      border-bottom: 1px solid rgb(242, 242, 242);
      background: #f2f2f2;
      border-radius: 8px;
      margin: 8px 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .forward {
        margin: 2px 30px;
      }
    }
  }
}
</style>
