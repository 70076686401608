
<template>
  <div
    v-if="loadedPage"
    class="admin-qr-hunt-content--container">

    <EntityEditor
      v-if="loadedPage"
      :title="game.name"
      entity-type="QrHuntGame"
      :entity="game"
      :admin="true"/>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EventBus from "../resources/eventBus";
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';
import EntityEditor from '../stories/components/EntityEditor/EntityEditor.vue';

export default {
  name: 'AdminGamesQrHunt',
  components: {
    EntityEditor
  },
  props: [
    'gameType',
    'gameId'
  ],
  mixins: [BeforeEnterMixin],
  mounted() {
    this.loadPage();
    this.setPageTitle('Admin', this.gameType);
  },
    destroyed() {
    EventBus.$emit('selected', '');
  },
  computed: {
    ...mapGetters('games', ["game", "userStatus"]),
  },
  watch: {
    gameId(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadPage();
      }
    }
  },
  data() {
    return {
      loadedPage: false,
    };
  },
  methods: {
    loadPage() {
      this.loadedPage = false;
      this.$store.dispatch('games/getAdminGame', { entityType: this.gameType, entityId: this.gameId, }).then(() => {
        this.loadedPage = true;
      });
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/scss/pages/AdminGame";
</style>
