import VueRouter from 'vue-router';

// Create Custom Router, to circumvent a vue-router bug with optional path parameters: https://github.com/vuejs/vue-router/issues/2110
// Needed for supporting our optional :market parameter in routes, namely ensuring that active-class works with navigation workaround (beforeEach in index.js)
export default class CustomVueRouter extends VueRouter {
  // Override resolve method. Fixes router-link active-class not working when the :market param is not explicitly defined.
  resolve(to, current, append) {
      // Append the :market param to the 'to' route if it exists on the current.
      if (current && current.params && current.params.market) {
          if (to.params)
              to.params.market = current.params.market;
          else
              to.params = { market: current.params.market };
      }
      return super.resolve(to, current, append);
  }
  // Override replace method. Fixes replace usage when the :market param is not provided, but the current route has it defined.
  replace(location) {
      // Append the :market param to the 'location' for replacing if it exists on the current route.
      if (this.history && this.history.current && this.history.current.params && this.history.current.params.market) {
          if (location.params)
              location.params.market = this.history.current.params.market;
          else
              location.params = { market: this.history.current.params.market };
      }
      return super.replace(location);
  }

  push(location) {
    return super.push(location).catch((error) => {});
  }
}
