<template>
  <div
    v-if="activation && offer"
    :class="{ 'pv-view': !preview, 'pv-view-contained': preview }"
    :style="previewContainerStyle"
  >
    <div v-if="notFound">
      <div class="user-content--container">
        <div class="activation-notfound--box">
          <iconComponent
            symbol="closed"
            class="activation-notfound--closed"
            stroke="#FFFFFF"/>
          <h2 class="activation-notfound--header">Activation no longer available</h2>
          <p class="activation-notfound--expired">Sorry, this activation has expired and is no longer available</p>
          <p class="btn btn-activate" @click="goBack()">Explore more</p>
        </div>
      </div>
    </div>
    <div
      v-else-if="loadedPage"
      class="pv-wrapper"
      :style="`background-image: url(${activation.fe_media?.file}); color: ${activation.text_color};`"
    >

      <div v-show="loading" class="pv-loading-overlay">
        <img
            class="header-logo"
            src="../../../assets/images/icons8-spinning-circle.gif" />
      </div>

      <div class="pv-content">
        <div class="pv-breadcrumb">
          <div class="pv-breadcrumb__container">
            <span v-if="activation.receipt_step" :class="{'visited' : stepNumber >= 0 }"></span>
            <span v-if="activation.registration_step" :class="{'visited' : stepNumber >= 1 }"></span>
            <span v-if="activation.survey_step" :class="{'visited' : stepNumber >= 2 }"></span>
            <span v-if="activation.address_step" :class="{'visited' : stepNumber >= 3 }"></span>
            <span v-if="activation.review_step" :class="{'visited' : stepNumber >= 4 }"></span>
            <span v-if="activation.cashback_step" :class="{'visited' : stepNumber >= 5 }"></span>
            <span :class="{'visited' : stepNumber >= 6 }"></span>
          </div>


          <iconComponent
                        v-if="activation.terms || activation.terms_text"
                        symbol="info"
                        @click="tocModal = true"
                        style="cursor: pointer;" />
          <iconComponent
                        v-if="(stepToShow === 'receipt-edit' && !edit) || stepToShow === 'receipt-input'"
                        symbol="question"
                        :fill="receiptIssueBubble ? 'white' : 'black'"
                        :stroke="receiptIssueBubble ? 'white' : 'black'"
                        @click="openFaqModal()"
                        :style="`cursor: pointer; ${receiptIssueBubble ? `background: ${activation.accent_color}` : ''}`"/>
          <div
              v-if="activation.receipts && activation.receipts.length > 1"
              @click="openAttemptsModal()"
              class="receipts-counter"
              :style="`background: ${activation.accent_color}`">
            {{ activation.receipts.length }}
          </div>

          <div
            v-if="receiptIssueBubble && !receiptData.receiptItem &&
            ((stepToShow === 'receipt-edit' && !edit)
              || stepToShow === 'receipt-input')"
            class="pv-edit-step__something-wrong bubble speech">
            <div class="pv-edit-step__something-wrong-header">
              <h4>{{ transl.UserPV.pleasetryscanagain }}</h4>
              <iconComponent symbol="cross" @click="receiptIssueBubble = false;"/>
            </div>
            <div
                v-if="stepToShow === 'receipt-edit'"
                class="pv-edit-step__something-wrong-body">
              <iconComponent symbol="errorCircle" fill="#DB0061" stroke="#DB0061" @click="receiptIssueBubble = false;"/>
              <div>{{ transl.UserPV.issuewithreceipt }}</div>
            </div>
            <div class="pv-edit-step__something-wrong-text-image">
              <div>
                <div>{{ transl.UserPV.includethis }}</div>
                <ul>
                  <li>
                    {{ transl.UserPV.storename }}
                  </li>
                  <li>
                    {{ transl.UserPV.products }}
                  </li>
                  <li>
                    {{ transl.UserPV.date }}
                  </li>
                </ul>
              </div>
              <img :src="`${windowOrigin}/api/nar/file/Enginio_Reciept_Small.jpg`" alt="">
            </div>
            <div class="pv-edit-step__something-wrong-note">{{ transl.UserPV.pleasenote }}</div>
            <div class="pv-edit-step__something-wrong-onlyaccept">{{ transl.UserPV.weonlyaccept }}</div>
            <div @click="openFaqModal()" class="enginio-link">{{ transl.UserPV.learnmore }}</div>
          </div>
        </div>

        <div v-if="stepToShow !== 'done' && (offer.fe_thumbnail && offer.fe_thumbnail.file) || (offer.fe_media && offer.fe_media[0] && offer.fe_media[0].file)" class="pv-desktop-activation">
          <img :src="offer.fe_thumbnail && offer.fe_thumbnail.file ? offer.fe_thumbnail.file : offer.fe_media[0].file" alt="">
          <div class="pv-desktop-activation__header">{{ offer.first_header }}</div>
          <div class="pv-desktop-activation__sub-header">{{ offer.quick_text }}</div>
          <div class="btn btn-activate"
              :style="`background: ${activation.accent_color};`"
              @click="$router.push({ name: 'HomeOffer', params: { offerId: getId(offer) } });">
            {{ transl.UserPV.back }}</div>
        </div>

        <div
            v-if="stepToShow === 'receipt-edit'"
            class="pv-edit-step__receipt">
          <div v-if="receipt.fe_media && receipt.fe_media.file">
            <vuePdf
                    v-if="receipt.fe_media.fileType.includes('pdf')"
                    class="pdf-component"
                    :src="`${host}/api/nar/file/${receipt.media.file}`"></vuePdf>
            <img v-else :src="receipt.fe_media.file" alt="">
          </div>
          <div v-if="authenticated" class="btn btn-whitebg-border"
              :style="`border-color: ${activation.accent_color}`">
            <label :style="`color: ${activation.accent_color}`"
                  for="myReceiptFileInput">{{ transl.UserPV.upload }}</label>
            <input
                  id="myReceiptFileInput"
                  type="file"
                  style="display:none"
                  accept="image/*;capture=camera"
                  @change="analyzeReceipt($event.target.files[0])">
          </div>
        </div>

        <div
            v-if="stepToShow === 'receipt-input'"
            class="pv-receipt-step">
          <!--<img
            v-if="activation.fe_icon"
            class="header-logo"
            :src="`${activation.fe_icon}`"/>-->

          <iconComponent class="pv-show-desktop" symbol="uploadReceipt" :width="35" />

          <h1>
            {{ transl.UserPV.registerreceipt }}
          </h1>

          <div v-if="authenticated" class="pv-receipt-step__scan"
                :style="`border: 2px solid ${activation.accent_color}`">
            <label :style="`color: ${activation.accent_color};`"
                  for="myReceiptFileInput">
              <div class="upload-label">
                <div>
                    <svg :style="`fill: ${activation.accent_color}`" fill="#000000" xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 50 50" width="50px" height="50px">
                      <path
                            d="M 19.398438 6 C 17.019531 6 14.96875 7.679688 14.5 10.011719 L 14.09375 12 L 9 12 C 6.238281 12 4 14.238281 4 17 L 4 38 C 4 40.761719 6.238281 43 9 43 L 41 43 C 43.761719 43 46 40.761719 46 38 L 46 17 C 46 14.238281 43.761719 12 41 12 L 35.90625 12 L 35.5 10.011719 C 35.03125 7.675781 32.980469 6 30.601563 6 Z M 19.398438 8 L 30.601563 8 C 32.023438 8 33.261719 9.011719 33.542969 10.40625 L 33.941406 12.394531 C 34.132813 13.328125 34.953125 14 35.90625 14 L 41 14 C 42.652344 14 44 15.347656 44 17 L 44 38 C 44 39.652344 42.652344 41 41 41 L 9 41 C 7.347656 41 6 39.652344 6 38 L 6 17 C 6 15.347656 7.347656 14 9 14 L 14.09375 14 C 15.046875 14 15.867188 13.328125 16.058594 12.394531 L 16.457031 10.40625 C 16.738281 9.011719 17.976563 8 19.398438 8 Z M 25 17 C 19.476563 17 15 21.476563 15 27 C 15 32.523438 19.476563 37 25 37 C 30.523438 37 35 32.523438 35 27 C 35 21.476563 30.523438 17 25 17 Z M 25 19 C 29.410156 19 33 22.589844 33 27 C 33 31.410156 29.410156 35 25 35 C 20.589844 35 17 31.410156 17 27 C 17 22.589844 20.589844 19 25 19 Z" />
                    </svg>
                </div>
                <div class="upload--bottom">
                  {{ transl.UserPV.scanreceipt }}
                </div>
              </div>
            </label>
            <input
                  id="myReceiptFileInput"
                  type="file"
                  style="visibility:hidden;"
                  accept="image/*;capture=camera"
                  @change="analyzeReceipt($event.target.files[0])">
          </div>

          <div v-if="authenticated" class="pv-receipt-step__or">
            <h4>{{ transl.UserPV.or }}</h4>
          </div>

          <div v-if="authenticated" class="pv-receipt-step__upload pv-show-mobile-and-tablet"
              :style="`border: 2px solid ${activation.accent_color}`">
            <label :style="`color: ${activation.accent_color}`"
                  for="myReceiptFileInput">{{ transl.UserPV.upload }}</label>
            <input
                  id="myReceiptFileInput"
                  type="file"
                  style="visibility:hidden;"
                  accept="image/*;capture=camera"
                  @change="analyzeReceipt($event.target.files[0])">

            <div class="desktop" :style="`background: ${activation.accent_color}`">
              <label
                    for="myReceiptFileInput">{{ transl.UserPV.upload }}</label>
              <input
                    id="myReceiptFileInput"
                    type="file"
                    style="visibility:hidden;"
                    accept="image/*;capture=camera"
                    @change="analyzeReceipt($event.target.files[0])">
            </div>
          </div>
          <div v-if="authenticated" class="pv-receipt-step__upload pv-show-desktop">
            <label :style="`color: ${activation.accent_color}`"
                  for="myReceiptFileInput">{{ transl.UserPV.upload }}</label>
            <input
                  id="myReceiptFileInput"
                  type="file"
                  style="visibility:hidden;"
                  accept="image/*;capture=camera"
                  @change="analyzeReceipt($event.target.files[0])">

            <div class="desktop" :style="`background: ${activation.accent_color}`">
              <label
                    for="myReceiptFileInput">{{ transl.UserPV.upload }}</label>
              <input
                    id="myReceiptFileInput"
                    type="file"
                    style="visibility:hidden;"
                    accept="image/*;capture=camera"
                    @change="analyzeReceipt($event.target.files[0])">
            </div>
          </div>

          <div v-if="!authenticated" class="pv-receipt-step__not-auth">
            <div class="pv-receipt-step__scan"
                :style="`border: 2px solid ${activation.accent_color}`">
              <label :style="`color: ${activation.accent_color};`"
                  for="myReceiptFileInput">
                <div class="upload-label">
                  <div>
                      <svg :style="`fill: ${activation.accent_color}`" fill="#000000" xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 50 50" width="50px" height="50px">
                        <path
                              d="M 19.398438 6 C 17.019531 6 14.96875 7.679688 14.5 10.011719 L 14.09375 12 L 9 12 C 6.238281 12 4 14.238281 4 17 L 4 38 C 4 40.761719 6.238281 43 9 43 L 41 43 C 43.761719 43 46 40.761719 46 38 L 46 17 C 46 14.238281 43.761719 12 41 12 L 35.90625 12 L 35.5 10.011719 C 35.03125 7.675781 32.980469 6 30.601563 6 Z M 19.398438 8 L 30.601563 8 C 32.023438 8 33.261719 9.011719 33.542969 10.40625 L 33.941406 12.394531 C 34.132813 13.328125 34.953125 14 35.90625 14 L 41 14 C 42.652344 14 44 15.347656 44 17 L 44 38 C 44 39.652344 42.652344 41 41 41 L 9 41 C 7.347656 41 6 39.652344 6 38 L 6 17 C 6 15.347656 7.347656 14 9 14 L 14.09375 14 C 15.046875 14 15.867188 13.328125 16.058594 12.394531 L 16.457031 10.40625 C 16.738281 9.011719 17.976563 8 19.398438 8 Z M 25 17 C 19.476563 17 15 21.476563 15 27 C 15 32.523438 19.476563 37 25 37 C 30.523438 37 35 32.523438 35 27 C 35 21.476563 30.523438 17 25 17 Z M 25 19 C 29.410156 19 33 22.589844 33 27 C 33 31.410156 29.410156 35 25 35 C 20.589844 35 17 31.410156 17 27 C 17 22.589844 20.589844 19 25 19 Z" />
                      </svg>
                  </div>
                  <div class="upload--bottom">
                    {{ transl.UserPV.scanreceipt }}
                  </div>
                </div>
              </label>
            </div>

            <div class="pv-receipt-step__or">
              <h4>{{ transl.UserPV.or }}</h4>
            </div>

            <div class="pv-receipt-step__upload pv-show-mobile-and-tablet"
                :style="`border: 2px solid ${activation.accent_color}`">
              <label :style="`color: ${activation.accent_color}`" @click="showLogin()"
                    for="myReceiptFileInput">{{ transl.UserPV.upload }}</label>

              <!--<div class="desktop" :style="`background: ${activation.accent_color}`">
                <label :style="`color: ${activation.text_color} !important`" @click="showLogin()" for="myReceiptFileInput">{{ transl.UserPV.upload }}</label>
              </div>-->
            </div>
            <div class="pv-receipt-step__upload pv-show-desktop">
              <label @click="showLogin()"
                    for="myReceiptFileInput">{{ transl.UserPV.upload }}</label>

              <div class="desktop" :style="`background: ${activation.accent_color}`">
                <label @click="showLogin()"
                      for="myReceiptFileInput">{{ transl.UserPV.upload }}</label>
              </div>
            </div>
          </div>

          <div class="pv-tip">
            {{ transl.UserPV.tip }}
          </div>
        </div>

        <div
            class="pv-edit-step"
            v-else-if="stepToShow === 'receipt-edit' && !edit">
          <h2>
            {{ transl.UserPV.scanned }}
          </h2>

          <div v-if="!preview" class="pv-edit-step__scan-again">
            <label for="myReceiptFileInput" style="display: grid; grid-template-columns: 50px 1fr;">
              <div :style="`background: ${activation.accent_color}`">
                <svg :style="`fill: white`" fill="#000000" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 50 50" width="50px" height="50px">
                  <path
                        d="M 19.398438 6 C 17.019531 6 14.96875 7.679688 14.5 10.011719 L 14.09375 12 L 9 12 C 6.238281 12 4 14.238281 4 17 L 4 38 C 4 40.761719 6.238281 43 9 43 L 41 43 C 43.761719 43 46 40.761719 46 38 L 46 17 C 46 14.238281 43.761719 12 41 12 L 35.90625 12 L 35.5 10.011719 C 35.03125 7.675781 32.980469 6 30.601563 6 Z M 19.398438 8 L 30.601563 8 C 32.023438 8 33.261719 9.011719 33.542969 10.40625 L 33.941406 12.394531 C 34.132813 13.328125 34.953125 14 35.90625 14 L 41 14 C 42.652344 14 44 15.347656 44 17 L 44 38 C 44 39.652344 42.652344 41 41 41 L 9 41 C 7.347656 41 6 39.652344 6 38 L 6 17 C 6 15.347656 7.347656 14 9 14 L 14.09375 14 C 15.046875 14 15.867188 13.328125 16.058594 12.394531 L 16.457031 10.40625 C 16.738281 9.011719 17.976563 8 19.398438 8 Z M 25 17 C 19.476563 17 15 21.476563 15 27 C 15 32.523438 19.476563 37 25 37 C 30.523438 37 35 32.523438 35 27 C 35 21.476563 30.523438 17 25 17 Z M 25 19 C 29.410156 19 33 22.589844 33 27 C 33 31.410156 29.410156 35 25 35 C 20.589844 35 17 31.410156 17 27 C 17 22.589844 20.589844 19 25 19 Z" />
                </svg>
              </div>
              <span style="align-self: center;">{{ transl.UserPV.scanagain }}</span>
            </label>
            <input
                  id="myReceiptFileInput"
                  type="file"
                  accept="image/*;capture=camera"
                  @change="analyzeReceipt($event.target.files[0])">
          </div>
          <div class="pv-edit-step__receipt-info">
            <div class="pv-edit-step__receipt-info-header">{{ transl.UserPV.purchasedate }}</div>
            <div class="pv-edit-step__receipt-info-subheader" :class="{ 'incorrect-field': !receiptData.receiptDate }">{{ receiptData.receiptDate || transl.UserPV.notfound || 'Not found!' }}</div>
            <div class="pv-edit-step__receipt-info-header">{{ transl.UserPV.product }}</div>
            <div class="pv-edit-step__receipt-info-subheader" :class="{ 'incorrect-field': !receiptData.receiptItem }">{{ receiptData.receiptItem || transl.UserPV.notfound || 'Not found!' }}</div>
            <div class="pv-edit-step__receipt-info-header">{{ transl.UserPV.amount }}</div>
            <div class="pv-edit-step__receipt-info-subheader" :class="{ 'incorrect-field': !receiptData.receiptItemCost }">{{ receiptData.receiptItemCost || transl.UserPV.notfound || 'Not found!' }}</div>
            <div class="pv-edit-step__receipt-info-header">{{ transl.UserPV.store }}</div>
            <div class="pv-edit-step__receipt-info-subheader" :class="{ 'incorrect-field': !receiptData.receiptStore }">{{ receiptData.receiptStore || transl.UserPV.notfound || 'Not found!' }}</div>
          </div>

          <div class="pv-edit-step__buttons-container">
            <p>{{ transl.UserPV.note }}</p>
            <div>
              <div
                  v-if="canEditReceipt"
                  class="btn btn-whitebg-border"
                  :style="`border-color: ${activation.accent_color}; color: ${activation.accent_color} !important`"
                  @click="edit = !edit">{{ transl.UserPV.edit }}</div>
              <div class="btn btn-activate" :style="`background: ${activation.accent_color}`"
                  @click="changeReceipt({ setReceipt: true })">{{ transl.UserPV.complete }}</div>
            </div>
          </div>
        </div>

        <div
            v-else-if="stepToShow === 'receipt-edit' && edit"
            class="pv-edit-step">
          <!--<img
            v-if="activation.fe_icon"
            class="header-logo"
            :src="`${activation.fe_icon}`"/>-->
          <h2>
            {{ transl.UserPV.editreceipt }}
          </h2>

          <!--<div class="pv-edit-step__scan-again">
            <div>
              <img src="../assets/images/camera.svg" alt="">
            </div>
            <label for="myReceiptFileInput">{{ transl.UserPV.scanagain }}</label>
            <input
              id="myReceiptFileInput"
              type="file"
              accept="image/*;capture=camera"
              @change="analyzeReceipt($event.target.files[0])">
          </div>-->

          <div class="pv-receipt-step__input-field">
            <InputField :disabled="!activation.receipt_can_change_date || preview" :label="transl.UserPV.purchasedate" v-model="receiptEditData.receiptDate"/>
            <InputField :disabled="!activation.receipt_can_change_item || preview" :label="transl.UserPV.product" v-model="receiptEditData.receiptItem"/>
            <InputField :disabled="!activation.receipt_can_change_item_cost || preview" :label="transl.UserPV.amount" v-model="receiptEditData.receiptItemCost"/>
            <InputField :disabled="!activation.receipt_can_change_store || preview" :label="transl.UserPV.store" v-model="receiptEditData.receiptStore"/>
          </div>

          <div class="pv-edit-step__buttons-container">
            <p>{{ transl.UserPV.note2 }}</p>
            <div>
              <div class="btn btn-whitebg-border"
                  :style="`border-color: ${activation.accent_color}; color: ${activation.accent_color} !important`"
                  @click="undoReceiptEdit">
                {{ transl.UserPV.undo }}</div>
              <div class="btn btn-activate" :style="`background: ${activation.accent_color}`"
                  @click="saveReceiptEdit">
                {{ transl.UserPV.save }}</div>
            </div>
          </div>

        </div>

        <div
            v-else-if="stepToShow === 'registration'"
            class="pv-registration-step"
            :class="{ 'pv-no-auth-step': !authenticated }">
          <div v-if="!authenticated" class="pv-before-we-start">
            <h3>{{ transl.UserPV.beforewestart }}</h3>
            <div class="pv-before-we-start-body">
              {{ transl.UserPV.beforeregister }}
            </div>
            <div class="btn btn-activate" @click="showLogin('signupModalView')">
              {{ transl.App.register }}
            </div>
            <div>{{ transl.Login.or }}</div>
            <div class="btn btn-transparent" @click="showLogin('loginModalView')">
              {{ transl.Login.login }}
            </div>
          </div>
          <div v-else>
            <h2 style="margin-bottom: 20px;">{{ transl.UserPV.registerreceipt }}</h2>
            <div v-if="activation.registration_serial" style="margin-bottom: 20px">
              <div style="text-align: left; padding-bottom: 5px;">
                {{ transl.UserPV.serialnumber }}
              </div>
              <InputField
                :outlined="true" :filled="false"
                :color="['white', `${activation.accent_color}` ]"
                v-model="registrationSerialnr"/>
              <p style="margin: 15px 0 30px 0;">{{ serialNumberDescription }}</p>
            </div>
            <div v-else-if="activation.registration_imei" style="margin-bottom: 20px">
              <div style="text-align: left; padding-bottom: 5px;">
                {{ transl.UserPV.imei }}
              </div>
              <InputField
                :outlined="true" :filled="false"
                :color="['white', `${activation.accent_color}` ]"
                v-model="registrationImeinr"/>
              <p style="margin: 20px 0;">{{ imeiNumberDescription }}</p>
            </div>
            <div v-if="activation.registration_modelnr" style="margin-bottom: 20px">
              <div>
                <AutoComplete
                  class="pv-show-tablet-and-desktop"
                  :value="registrationModelnr"
                  :options="modelNumberOptions"
                  :limit="4"
                  :accentColor="activation.accent_color"
                  :title="transl.UserPV.choosemodelnr"
                  :placeholder="transl.UserPV.findbyname"
                  :noResult="transl.UserPV.nomodelnr"
                  @selected="registrationModelnr = $event.text"/>
                <AutoComplete
                  class="pv-show-mobile"
                  :value="registrationModelnr"
                  :options="modelNumberOptions"
                  :limit="4"
                  :textColor="activation.text_color"
                  :accentColor="activation.accent_color"
                  :title="transl.UserPV.choosemodelnr"
                  :placeholder="transl.UserPV.findbyname"
                  :noResult="transl.UserPV.nomodelnr"
                  @selected="registrationModelnr = $event.text"/>
              </div>
              <!--<p style="margin: 30px 0;">{{ transl.UserPV.copymodelnr }}</p>-->
            </div>
            <div class="btn btn-activate" :style="`background: ${activation.accent_color}`" @click="changeReceipt({ setRegistration: true })">
              {{ transl.UserPV.register }}
            </div>
          </div>
        </div>

        <div
            v-else-if="stepToShow === 'survey'"
            class="pv-survey-step"
            :class="{ 'pv-no-auth-step': !authenticated }">

          <div v-if="!authenticated" class="pv-before-we-start">
            <h3>{{ transl.UserPV.beforewestart }}</h3>
            <div class="pv-before-we-start-body">
              {{ transl.UserPV.beforesurvey }}
            </div>
            <div class="btn btn-activate" @click="showLogin('signupModalView')">
              {{ transl.App.register }}
            </div>
            <div>{{ transl.Login.or }}</div>
            <div class="btn btn-transparent" @click="showLogin('loginModalView')">
              {{ transl.Login.login }}
            </div>
          </div>
          <div v-else>
            <div class="pv-survey-step__header">
              <h2>{{ transl.UserPV.surveyheader }}</h2>
              <p>{{ transl.UserPV.surveysubheader }}</p>
            </div>

            <Form
              class="pv-survey pv-show-mobile"
              :questions="activation.survey"
              :answers="surveyAnswers"
              :accentColor="activation.accent_color"
              :textColor="activation.text_color"
              :submitButton="transl.UserPV.submit"
              @answeredAll="surveyAnswers = $event"
              @missingRequired="surveyAnswers = {}"
              @submit="changeReceipt({ setSurvey: true })"/>

            <Form
              class="pv-survey pv-show-tablet-and-desktop"
              :questions="activation.survey"
              :answers="surveyAnswers"
              :accentColor="activation.accent_color"
              textColor="black"
              :submitButton="transl.UserPV.submit"
              @answeredAll="surveyAnswers = $event"
              @missingRequired="surveyAnswers = {}"
              @submit="changeReceipt({ setSurvey: true })"/>
          </div>
        </div>

        <div
            v-else-if="stepToShow === 'address'"
            class="pv-form-step"
            :class="{ 'pv-no-auth-step': !authenticated }">

          <div v-if="!authenticated" class="pv-before-we-start">
            <h3>{{ transl.UserPV.beforewestart }}</h3>
            <div class="btn btn-activate" @click="showLogin('signupModalView')">
              {{ transl.App.register }}
            </div>
            <div>{{ transl.Login.or }}</div>
            <div class="btn btn-transparent" @click="showLogin('loginModalView')">
              {{ transl.Login.login }}
            </div>
          </div>
          <div v-else>
            <div class="pv-form__header">
              <h2>{{ transl.UserPV.surveyheader }}</h2>
              <p>{{ transl.UserPV.surveysubheader }}</p>
            </div>

            <Form
                class="pv-form show-mobile"
                :questions="addressForm"
                :answers="addressData"
                :accentColor="activation.accent_color"
                :textColor="activation.text_color"
                :submitButton="transl.UserPV.submit"
                @answeredAll="addressData = $event"
                @missingRequired="addressData = {}"
                @submit="changeReceipt({ setAddress: true })"/>

            <Form
                class="pv-form show-tablet-and-desktop"
                :questions="addressForm"
                :answers="addressData"
                :accentColor="activation.accent_color"
                textColor="black"
                :submitButton="transl.UserPV.submit"
                @answeredAll="addressData = $event"
                @missingRequired="addressData = {}"
                @submit="changeReceipt({ setAddress: true })"/>
          </div>
        </div>

        <div
            v-else-if="stepToShow === 'cashback'"
            class="pv-cashback-step"
            :class="{ 'pv-no-auth-step': !authenticated }">
            <!-- allow="autoplay; fullscreen" allowfullscreen-->
          <div v-if="!authenticated" class="pv-before-we-start">
            <h3>{{ transl.UserPV.beforewestart }}</h3>
            <div class="pv-before-we-start-body">
              {{ transl.UserPV.beforecashback }}
            </div>
            <div class="btn btn-activate" @click="showLogin('signupModalView')">
              {{ transl.App.register }}
            </div>
            <div>{{ transl.Login.or }}</div>
            <div class="btn btn-transparent" @click="showLogin('loginModalView')">
              {{ transl.Login.login }}
            </div>
          </div>
          <iframe
            v-else-if="cashbackToken !== ''"
            id="cashback-iframe"
            :src="`https://paylink.svepos.se/cashback?token=${cashbackToken}`"
            frameborder="0"></iframe>
            <!--<iframe
            id="cashback-iframe"
            frameborder="0"></iframe>-->
        </div>

        <div v-else-if="step.includes('issue')" class="pv-issue">
          <h2>{{ transl.UserPV.ohoh }}</h2>
          <!-- TODO: add feature?
          <p>Alla dina skannade kvitton sparas under din profil.</p>-->
          <div class="pv-completed__mail">
            <iconComponent symbol="heartOnHand" />
            <!--the prizes are out of stock-->
            <p v-if="step.includes('out of stock')">
              {{ transl.UserPV.noprize }}
            </p>
            <p v-if="step.includes('tomorrow')">
              {{ transl.UserPV.tryagaintomorrow }}
            </p>
          </div>

          <div class="pv-completed__back-btn btn">
            <p style="padding-bottom: 10px">{{ transl.UserPV.dontforget }}</p>
            <div
                :style="`background: ${activation.accent_color}`"
                class="btn btn-activate"
                @click="$router.push({ name: 'HomeGoodiebag', params: { goodiebagId: getId(offer.goodiebag) } });">
              {{ transl.UserPV.backto.interpolate({ companyname: offer.company.name }) }}
            </div>
          </div>
        </div>

        <div v-else class="pv-completed">
          <h2>It's Enginio</h2>

          <div class="pv-completed__mail" v-if="activation.cta_done_custom_message">
            <div v-if="activation.cta_code_visible">{{ transl.UserPV.yourcode }}</div>
            <div v-if="activation.cta_code_visible" class="cta-code">{{ receipt.cta_code }}</div>
            <div v-if="activation.cta_code_visible" class="a-line"></div>

            <iconComponent :symbol="`${activation.cta_done_custom_icon || 'confetti'}`"/>
            <p>{{ activation.cta_done_custom_message }}</p>
          </div>

          <div class="pv-completed__mail" v-else-if="activation.cta_email || activation.cta_code_visible">
            <div v-if="activation.cta_code_visible">{{ transl.UserPV.yourcode }}</div>
            <div v-if="activation.cta_code_visible" class="cta-code">{{ receipt.cta_code }}</div>

            <div v-if="activation.cta_email && activation.cta_code_visible" class="a-line"></div>

            <iconComponent v-if="activation.cta_email" symbol="email" />
            <p v-if="activation.cta_email">{{ transl.UserPV.emailsoon }}</p>
            <!--TODO: add the_prize as a variable!-->
          </div>

          <div class="prize-container">
            <p
              v-if="!activation.cta_done_url"
              style="padding-bottom: 10px">{{ transl.UserPV.dontforget }}</p>
            <div class="pv-completed__back-btn">
              <div
                  v-if="activation.cta_done_url"
                  :style="`background-color: ${activation.accent_color}; margin:10px`"
                  class="btn btn-activate"
                  @click="openUrl(activation.cta_done_url, true)">
                  <label :style="`color: ${activation.text_color}; margin: 0;`">{{ activation.cta_done_button }}</label>
              </div>
              <div
                  v-else
                  :style="`background: ${activation.accent_color}`"
                  class="btn btn-activate"
                  @click="$router.push({ name: 'HomeGoodiebag', params: { goodiebagId: getId(offer.goodiebag) } });">
                {{ activation.cta_done_button || transl.UserPV.backto.interpolate({ companyname: offer.company.name }) }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="this.shouldShowTOC" class="fake-modal">
        <div class="fake-modal-content">
          <div class="terms-wrapper">
            <div v-if="activation.terms_text" v-html="activation.terms_text" class="html-text"></div>
            <vuePdf v-else :page="pageNr" :src="`${host}/api/nar/file/${activation.terms}`" @num-pages="pageCount = $event">
            </vuePdf>
          </div>
          <div class="terms-buttons">
            <div
              v-if="authenticated && activation.terms_merchandise && !receipt.accepted_merchandise && !receipt.accepted_terms"
              class="terms-merchandise-grid">
              <inputField
                :value="activation.terms_merchandise_default"
                inputType="checkbox"
                onclick="return false;"/>
              <span
                class="terms-merchandise-text"
                v-html="activation.terms_merchandise"></span>
            </div>

            <div v-if="authenticated && !receipt.accepted_terms" class="btn btn-activate" @click="acceptTerms()">
              {{ transl.UserPV.accept }}
            </div>

            <div class="btn btn-secondary" @click="tocModal = false">
              {{ transl.UserPV.close }}
            </div>
          </div>
        </div>
      </div>

      <!-- <modal class="fullscreen-dialog" v-if="shouldShowTOC" @close="tocModal = false" modalType="fullscreen" padding="0" :terms="true">
        <slot>
          <div class="terms-wrapper">
            <div v-if="activation.terms_text" v-html="activation.terms_text" class="html-text"></div>
            <vuePdf v-else :page="pageNr" :src="`${host}/api/nar/file/${activation.terms}`" @num-pages="pageCount = $event">
            </vuePdf>
          </div>
          <div class="terms-buttons">
            <div
              v-if="authenticated && activation.terms_merchandise && !receipt.accepted_merchandise && !receipt.accepted_terms"
              class="terms-merchandise-grid">
              <inputField
                v-model="acceptMerchandise"
                inputType="checkbox"/>
              <span
                class="terms-merchandise-text"
                v-html="activation.terms_merchandise"></span>
            </div>

            <div v-if="authenticated && !receipt.accepted_terms" class="btn btn-activate" @click="acceptTerms()">
              {{ transl.UserPV.accept }}
            </div>

            <div class="btn btn-secondary" @click="tocModal = false">
              {{ transl.UserPV.close }}
            </div>
          </div>
        </slot>
      </modal> -->

      <modal v-if="notVerifiedEmailModal" class="fullscreen-dialog"
            @close="notVerifiedEmailModal = false;" modalType="fullscreen">
        <slot>
          <div class="competition-info">
            <iconComponent
                          symbol="emailWithPlus"
                          :width="150"
                          :stroke-width="0"
                          fill="#EA7801" />

            <div class="internal-link-title-3">
              {{ transl.Offer.youmustverify }}
            </div>
            <div class="internal-link-title-4 internal-link-center">
              {{ transl.Offer.mustverifyemail }}
            </div>
            <div
                class="btn btn-activate"
                @click="verifyEmail()">
              {{ transl.Offer.gotoprofile }}
            </div>
            <Hero
                  class="ok-stamp"
                  type="Integrity_badge_Light" />
            <!--<div class="modal-terms">
              {{ transl.Offer.shareyou2 }}
              <span @click="openurl(faqUrl)">FAQ.</span>
            </div>-->
          </div>
        </slot>
      </modal>

      <modal v-if="atteptsModal" class="fullscreen-dialog"
            @close="atteptsModal = false;" modalType="fullscreen">
        <slot>
          <div class="competition-info">

            <div class="internal-link-title-3">
              <!-- TODO: copy -->
              You have been here before
            </div>
            <div class="internal-link-title-4 internal-link-center">
              {{ activation.receipts.length }}
              <!-- TODO: copy -->
              times have you previously been activated
            </div>
          </div>
        </slot>
      </modal>
    </div>
  </div>
</template>

<script>
import InputField from '../InputField/InputField.vue';
import AutoComplete from '../AutoComplete/AutoComplete.vue';
import Form from '../Form/Form.vue';
import { mapGetters } from "vuex";

export default {
  name: 'PVView',
  components: {
    // PartnerModal,
    InputField,
    AutoComplete,
    Form,
  },
  props: {
    activation: {
      type: Object,
    },
    offer: {
      type: Object,
    },
    parentCompany: {
      type: Object,
    },
    showTOC: {
      type: Boolean,
    },
    currentStep: {
      type: String,
    },
    preview: {
      type: Boolean,
      default: false,
    },
    previewWidth: {
      type: Number,
    },
    previewHeight: {
      type: Number,
    },
    authenticated: {
      type: Boolean,
      default: false,
    },
    receiptData: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters(['user']),
    previewContainerStyle() {
      if (!this.preview || !this.previewHeight || !this.previewWidth) return {};

      const minHeight = this.previewHeight && {
        minHeight: `${this.previewHeight}px`,
      }

      const minWidth = this.previewWidth && {
        minWidth: `${this.previewWidth}px`,
      }

      return {
        ...minWidth,
        ...minHeight
      }
    },
    shouldShowTOC() {
      return this.showTOC ?? this.tocModal;
    },
    stepToShow() {
      return this.currentStep ?? this.step;
    },

    canEditReceipt () { // Modified
      if (this.activation?._id) {
        return this.activation.receipt_can_change_item
          || this.activation.receipt_can_change_item_cost
          || this.activation.receipt_can_change_store
          || this.activation.receipt_can_change_date;
      }

      return false;
    },
    stepNumber() { // Modified
      switch (this.stepToShow) {
        case 'receipt-input':
          return 0;
        case 'registration':
          return 1;
        case 'survey':
          return 2;
        case 'address':
          return 3;
        case 'review':
          return 4;
        case 'cashback':
          return 5;
        case 'done':
          return 6;
        default:
          return 0;
      }
    },
    modelNumberOptions() {
      return this.activation?.registration_modelnrs.map(modelnr => {
        return { text: modelnr, _id: modelnr };
      })
    },
    serialNumberDescription() {
      return this.activation?.registration_serial_description || this.transl.UserPV.copyserialnr;
    },
    imeiNumberDescription() {
      return this.activation?.registration_imei_description || this.transl.UserPV.copyimei;
    },
  },
  mounted() {
    this.addressForm = this.getAddressForm();
    this.addressData = this.getAddressData();
  },
  watch: {
    'activation.address_state'(active) {
      if (active) {
        this.addressForm.push({
          label: 'Ange ditt landskap',
          model: 'state',
          id: 'state',
        });
        return;
      }

      this.addressForm.splice(this.addressForm.findIndex(item => item.id === 'state'), 1);
    },
    'activation.address_country'(active) {
      if (active) {
        this.addressForm.push({
          label: 'Ange ditt land',
          model: 'country',
          id: 'country',
        });
        return;
      }

      this.addressForm.splice(this.addressForm.findIndex(item => item.id === 'country'), 1);
    }
  },
  data() {
    return {
      loadedPage: true,
      loading: false,
      step: 'receipt-input',
      edit: false,
      disableAddReceipt: false,
      receipt: {},
      canAddReceipt: false,
      fileSize: 10 * 1000000, // 10 MB
      receiptFile: undefined,
      token: '',
      cashbackToken: '',
      host:
        process.env.NODE_ENV !== 'production'
          ? 'http://localhost:8003'
          : 'https://purchasevalidation.com',
      allowOrigin:
        process.env.NODE_ENV !== 'production'
          ? 'http://localhost:8080'
          : this.windowOrigin,
      // receiptData: {},
      receiptEditData: {},
      registrationSerialnr: '',
      registrationImeinr: '',
      registrationModelnr: '',
      surveyAnswers: {},
      surveyOther: {},
      answeredSurvey: false,
      tocModal: false,
      mustAcceptTerms: false,
      acceptMerchandise: true,
      pageCount: 0,
      pageNr: 1,
      notVerifiedEmailModal: false,
      receiptIssueBubble: false,
      atteptsModal: false,
      receiptValidText: '',
      notFound: false,
      addressForm: [],
      addressData: {},
    }
  },
  methods: {
    isReceiptDateWithinLiveDateRange(receipt_date){
      if(receipt_date)
      {
        let unixReceiptDate = new Date(receipt_date).getTime() / 1000; // convert to Unix time format
        if (unixReceiptDate >= this.activation.live_date_range_from && unixReceiptDate <= this.activation.live_date_range_to) {
            //Receipt is valid
          this.receiptValidText = `date is inside`;
          this.receiptValid = true;
        }
        else{
          //Receipt is not valid
          this.receiptValidText = `date is outside`;
          this.receiptValid = false;
        }
      }
    },
    reloadPage() {
      // In UserPV
    },
    setCashbackIframe() {
      // In UserPV
    },
    showLogin() {
      // In UserPV
    },
    verifyEmail() {
      // In UserPV
    },
    acceptTerms() {
      // In UserPV
    },
    setStep(activation, receipt, isReload) {
      let theReceipt = this.receipt;
      if (receipt && receipt._id) {
        theReceipt = receipt;
      }

      if (this.canAddReceipt) {
        theReceipt = {};
        this.receipt = {};
      }

      if (theReceipt && theReceipt._id) {
        this.receipt = theReceipt;
        // ToDo: Deal with editing
        // this.receiptData.receiptItem = theReceipt.receipt_item;
        // this.receiptData.receiptItemCost = theReceipt.receipt_item_cost;
        // this.receiptData.receiptStore = theReceipt.receipt_store;
        // this.receiptData.receiptDate = theReceipt.receipt_date;
        this.receiptEditData = JSON.parse(JSON.stringify(this.receiptData));
        const acceptedTerms = theReceipt.accepted_terms;

        //use this for client side validation. uncomment since only server side is used.
        //this.isReceiptDateWithinLiveDateRange(theReceipt.receipt_date);

        if (theReceipt.cta_issue) {
          if (theReceipt.cta_issue.includes('The codes are out of stock')) {
            this.step = 'issue - out of stock';
          }
        } else if (!acceptedTerms && activation.terms_must_accept) {
          this.mustAcceptTerms = true;
          this.tocModal = true;
        } else if (
          activation.receipt_step &&
          (!theReceipt.receipt_metadata ||
            (!(theReceipt.media && theReceipt.media.file) && !theReceipt.confirmed_receipt))
        ) {
          this.step = 'receipt-input';
        } else if (activation.receipt_step && !theReceipt.confirmed_receipt) {
          this.step = 'receipt-edit';
          if (!this.receiptData.receiptItem) {
            this.receiptIssueBubble = true;
          }
        } else if (activation.registration_step && !theReceipt.confirmed_registration) {
          this.step = 'registration';
        } else if (activation.survey_step && !theReceipt.confirmed_survey) {
          this.step = 'survey';
        } else if (activation.cashback_step && !theReceipt.confirmed_cashback) {
          this.step = 'cashback';
          this.setCashbackIframe();
        } else if (activation.recurring && theReceipt.cta_done && isReload) {
            // If the user is trying to upload multiple receipts on the same day
            this.step = 'issue - tomorrow';
        } else {
          this.step = 'done';
        }

        if (theReceipt.survey && theReceipt.survey.length) {
          this.surveyAnswers = theReceipt.survey;
        }

      } else if (this.authenticated) {
        this.addEmptyReceipt();
      } else {
        if (this.authenticated && activation.terms_must_accept) {
          this.mustAcceptTerms = true;
          this.tocModal = true;
        } else if (activation.receipt_step) {
          this.step = 'receipt-input';
        } else if (activation.registration_step) {
          this.step = 'registration';
        } else if (activation.survey_step) {
          this.step = 'survey';
        } else if (activation.cashback_step) {
          this.step = 'cashback';
          // this.setCashbackIframe();
        }
      }
    },

    analyzeReceipt(file) {
      // Implement?
    },

    addEmptyReceipt() {
      // Implement?
    },

    changeReceipt() {
      // Implement?
    },

    setAnsweredSurvey() {
      // Implement?
    },

    changePage(newPageNr) {
      if (newPageNr <= this.pageCount && newPageNr > 0) {
        this.pageNr = newPageNr;
      }
    },

    openFaqModal() {
      // Implement?
    },
    openAttemptsModal() {
      // Implement?
    },
    resizeCanvas() {
      // Implement?
    },

    goBack() {
      // Implement?
    },

    undoReceiptEdit() {
      this.edit = !this.edit;
      this.receiptEditData = JSON.parse(JSON.stringify(this.receiptData))
    },
    saveReceiptEdit() {
      this.step = 'receipt-edit';
      this.edit = !this.edit;
      this.$emit('saveReceiptChanges', JSON.parse(JSON.stringify(this.receiptEditData)))
    },
    getAddressForm() {
      return [
        {
          label: `${this.transl.Offer.firstname} ${this.transl.Offer.lastname}`,
          model: 'nameAtAddress',
          id: 'name',
        },
        {
          label: this.transl.Offer.street,
          model: 'address',
          id: 'address',
        },
        {
          label: this.transl.Offer.postalcode,
          model: 'postalCode',
          id: 'postal-code',
        },
        {
          label: this.transl.Offer.city,
          model: 'city',
          id: 'address-level2',
        },
      ];
    },
    getAddressData() {
      return {
        nameAtAddress: `${this.user.name} ${this.user.last_name}`,
        address: this.user.adress,
        postalCode: this.user.postal_code,
        city: this.user.city,
        state: this.user.state,
        country: this.user.country,
      };
    }

  }
}
</script>

<style lang="scss" scoped>
@import "./PVView.scss";
</style>
