<template>
  <div>
    <EmailTemplate v-if="user && isAdmin(user)" />
    <div v-else>Sorry, you must be an admin to proceed</div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import EmailTemplate from '../stories/components/EmailTemplate/EmailTemplate.vue';
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';

export default {
  name: 'EmailTesting',
  components: {EmailTemplate},
  mixins: [BeforeEnterMixin],
  data() {
    return {};
  },
  mounted() {},
  methods: {
  },
  computed: {
    ...mapGetters(["user"])
  },
};
</script>

<style scoped lang="scss">
@import "../assets/scss/pages/EmailTesting";
</style>
