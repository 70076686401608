import axios from 'axios';

export default {
  // Admin
  findAdminPVCompany: (data) => {
    return axios.put(`/api/pv/admin/companies/${data.companyId}/activations`, data)
      .then(response => {
        return {
          company: response.data.company != null
            ? response.data.company : {},
        }});
  },
  findAdminPVCompanyByReceiptProperties: (data) => {
    return axios.put('/api/pv/admin/activationsbyreceipt', data)
      .then(response => {
        return {
          company: response.data.company != null
            ? response.data.company : {},
        }});
  },
  findAdminPVActivation: (data) => {
    return axios.put(`/api/pv/admin/activations`, data)
      .then(response => {
        return {
          activations: response.data.activations != null
            ? response.data.activations : [],
        }});
  },


  addAdminPVActivation: (payload) => {
    return axios.post('/api/pv/admin/activations', payload)
      .then(response => ({
        activation: response.data.activation != null
          ? response.data.activation : {},
      }));
  },

  addAdminPVActivationMedia: (payload) => {
    return axios.put(`/api/pv/admin/activations/${payload.entityId}/picture`, payload.data, payload.config)
      .then(response => ({
        activation: response.data.activation != null
          ? response.data.activation : {},
      }));
  },

  addAdminPVActivationThumbnail: (payload) => {
    return axios.put(`/api/pv/admin/activations/${payload.entityId}/thumbnail`, payload.data, payload.config)
      .then(response => ({
        activation: response.data.activation != null
          ? response.data.activation : {},
      }));
  },

  addAdminPVActivationDonation: (payload) => {
    return axios.put(`/api/pv/admin/activations/${payload.entityId}/donation`, payload.data, payload.config)
      .then(response => ({
        activation: response.data.activation != null
          ? response.data.activation : {},
      }));
  },

  addAdminPVActivationIcon: (payload) => {
    return axios.put(`/api/pv/admin/activations/${payload.entityId}/icon`, payload.data, payload.config)
      .then(response => ({
        activation: response.data.activation != null
          ? response.data.activation : {},
      }));
  },

  addAdminPVActivationPdf: (payload) => {
    return axios.put(`/api/pv/admin/activations/${payload.entityId}/terms`, payload.data, payload.config)
      .then(response => ({
        activation: response.data.activation != null
          ? response.data.activation : {},
      }));
  },

  addAdminActivationEmailAttachment: (payload) => {
    return axios.put(`/api/pv/admin/activations/${payload.entityId}/emailActionAttachment`, payload.data, payload.config)
      .then(response => ({
        fileKey: response.data.fileKey != null
          ? response.data.fileKey : {},
      }));
  },

  saveAdminPVActivation: (payload) => {
    return axios.put(`/api/pv/admin/activations/${payload.entityId}`, payload)
      .then(response => ({
        activation: response.data.activation != null
          ? response.data.activation : {},
      }));
  },

  publiciseAdminPVActivation: (payload) => {
    return axios.put(`/api/pv/admin/activations/${payload.entityId}/publicise`, payload)
      .then(response => ({
        activation: response.data.activation != null
          ? response.data.activation : {},
      }));
  },

  cloneAdminPVActivation: (payload) => {
    return axios.post(`/api/pv/admin/activations/${payload.entityId}/clone`, payload)
      .then(response => ({
        activations: response.data.activations != null
          ? response.data.activations : [],
      }));
  },

  deleteAdminPVActivationPicture: (entityId) => {
    return axios.delete(`/api/pv/admin/activations/${entityId}/picture`)
      .then(response => ({
        activation: response.data.activation != null
          ? response.data.activation : {},
      }));
  },

  deleteAdminPVActivation: (payload) => {
    return axios.delete(`/api/pv/admin/activations/${payload.entityId}?deleteCode=${payload.deleteCode}`)
      .then(response => ({
        message: response.data.message != null
          ? response.data.message : '',
      }));
  },

  // Company
  findCompanyActivations: (data) => {
    return axios.put('/api/pv/c/activations', data)
      .then(response => {
        return { activations: response.data.activations != null
          ? response.data.activations : []}
        });
  },

  getCompanyActivation: (payload) => {
    return axios.get(`/api/pv/c/activations/${payload.activationId}`)
      .then(response => ({
        activation: response.data.activation != null
          ? response.data.activation : {},
      }));
  },

  addCompanyActivation: (payload) => {
    return axios.post('/api/pv/c/activations', payload)
      .then(response => ({
        activation: response.data.activation != null
          ? response.data.activation : {},
      }));
  },

  addCompanyActivationPicture: (payload) => {
    return axios.put(`/api/pv/c/activations/${payload.entityId}/picture`, payload.data, payload.config)
      .then(response => ({
        activation: response.data.activation != null
          ? response.data.activation : {},
      }));
  },

  addCompanyActivationIcon: (payload) => {
    return axios.put(`/api/pv/c/activations/${payload.entityId}/icon`, payload.data, payload.config)
      .then(response => ({
        activation: response.data.activation != null
          ? response.data.activation : {},
      }));
  },

  saveCompanyActivation: (payload) => {
    return axios.put(`/api/pv/c/activations/${payload.entityId}`, payload)
      .then(response => ({
        activation: response.data.activation != null
          ? response.data.activation : {},
      }));
  },

  publiciseCompanyActivation: (payload) => {
    return axios.put(`/api/pv/c/activations/${payload.entityId}/publicise`, payload)
      .then(response => ({
        activation: response.data.activation != null
          ? response.data.activation : {},
      }));
  },

  deleteCompanyActivation: (entityId) => {
    return axios.delete(`/api/pv/c/activations/${entityId}`)
      .then(response => ({
        message: response.data.message != null
          ? response.data.message : '',
      }));
  },
};
